import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { AutovalutazioniService } from 'src/app/services/autovalutazioni/autovalutazioni.service';
import { SpinnerOverlayComponent } from '../spinner-overlay/spinner-overlay.component';
import { SwiperContainer } from 'swiper/element';
import { Ambito } from 'src/app/services/ambiti/ambiti.service';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { Observable, firstValueFrom } from 'rxjs';
import { UnitaDiMisura, UnitaDiMisuraService } from 'src/app/services/unita-di-misura/unita-di-misura.service';
import { MatRadioChange } from '@angular/material/radio';
import { Filters } from '../lista-tabellare/classes/lista-tabellare-data-source';
import { Tematica } from 'src/app/services/tematiche/tematiche.service';

@Component({
  selector: 'app-compila-autovalutazione',
  templateUrl: './compila-autovalutazione.component.html',
  styleUrls: ['./compila-autovalutazione.component.scss']
})
export class CompilaAutovalutazioneComponent implements AfterViewInit {

  @Output() completato: EventEmitter<boolean> = new EventEmitter();

  /* @ViewChild(SpinnerOverlayComponent) spinnerOver!: SpinnerOverlayComponent; */
  @ViewChild('swiperAmbiti') swiperAmbiti!: ElementRef<SwiperContainer>;
  @ViewChildren('swiperTematiche') swiperTematiche!: QueryList<ElementRef<SwiperContainer>>;

  private _compilazione: any;
  private _salva?: (idAutovalutazioneCompilata: string, compilazione: any) => Observable<boolean>;

  @Input()
  set salva(salva: (idAutovalutazioneCompilata: string, compilazione: any) => Observable<boolean>) {
    this._salva = salva;
  }

  @Input()
  set compilazione(compilazione: any) {
    this._compilazione = compilazione;

    this.ambiti.forEach((ambito, idxTema) => {
      this.totTematiche += ambito.tematiche.length;
    });

    this._updateSwiperHeight();
  }

  get compilazione() {
    return this._compilazione!;
  }

  private _esercizio!: number;

  @Input()
  set esercizio(esercizio: number) {
    this._esercizio = esercizio;
  }

  get esercizio() {
    return this._esercizio;
  }

  private _idAutovalutazioneCompilata!: string;

  @Input()
  set idAutovalutazioneCompilata(idAutovalutazioneCompilata: string) {
    this._idAutovalutazioneCompilata = idAutovalutazioneCompilata;
  }

  get idAutovalutazioneCompilata() {
    return this._idAutovalutazioneCompilata;
  }

  private _temporaryToken: string | undefined = undefined;

  @Input()
  set temporaryToken(temporaryToken: string | undefined) {
    this._temporaryToken = temporaryToken;
  }

  get temporaryToken() {
    return this._temporaryToken;
  }


  public currentSlideIndex: number = 0;
  public totTematiche = 0;
  public titoloBtnAvanti = 'Avanti';
  public indexAmbitoCorrente = 0;
  public indexTematicaCorrente = 0;

  public arrayUm: { [idKpi: string]: UnitaDiMisura[] } = {};

  constructor(
    private autovalutazioneService: AutovalutazioniService,
    private utilityService: UtilityService,
    private unitaDiMisuraService: UnitaDiMisuraService
  ) {
  }

  ngAfterViewInit(): void {
    setTimeout(() => {

      //compilazione WS per recuperare 

      let _indiceTematica: number | undefined = undefined;
      let _indiceAmbito: number | undefined = undefined;

      for (let idxAmb = 0; idxAmb < this.ambiti.length; idxAmb++) {

        if (this.ambiti[idxAmb].tematiche.length) {
          for (let idxTema = 0; idxTema < this.ambiti[idxAmb].tematiche.length; idxTema++) {

            if (!this.ambiti[idxAmb].tematiche[idxTema].risposte?.length) {
              _indiceTematica = idxTema;
              _indiceAmbito = idxAmb;
              break;
            }
          }
        }

        if (_indiceTematica !== undefined && _indiceAmbito !== undefined) {
          break;
        }
      }

      this.swiperAmbiti.nativeElement.swiper.slideTo(_indiceAmbito || 0);

      if (_indiceAmbito !== undefined && this.swiperTematiche.get(_indiceAmbito)) {
        this.swiperTematiche.get(_indiceAmbito)!.nativeElement.swiper.slideTo(_indiceTematica || 0);
      }

      for (const ambito of this.ambiti) {

        if (ambito.tematiche.length) {

          for (const tematica of ambito.tematiche) {

            if (tematica.opzioni?.length) {

              for (const opzione of tematica.opzioni) {

                if (opzione.sottoOpzioni?.length) {

                  opzione.sottoOpzioni.forEach((sotOpz) => {

                    if (sotOpz.tipo === 'kpi' && sotOpz.idCategoriaUnitaDiMisura && !this.arrayUm[sotOpz.idCategoriaUnitaDiMisura]) {

                      this.arrayUm[sotOpz.idCategoriaUnitaDiMisura] = []; // si mette intanto array vuoto per evitare di chiamare il ws più volte per una singola categoria

                      const filters: Filters[] = [{
                        chiave: 'idCategoriaUnitaDiMisura',
                        operator: 'eq',
                        values: [sotOpz.idCategoriaUnitaDiMisura]
                      }];

                      let obs;

                      if (this._temporaryToken) {
                        obs = this.unitaDiMisuraService.getUnitaDiMisuraConTempToken(this._temporaryToken, 0, 1000, '', filters);
                      } else {
                        obs = this.unitaDiMisuraService.getUnitaDiMisura(0, 1000, '', filters);
                      }

                      obs.subscribe((um) => {
                        this.arrayUm[sotOpz.idCategoriaUnitaDiMisura] = um?.content || [];
                      });
                    }
                  });
                }
              }
            }
          }
        }
      }
    }, 0);
  }


  onRadioChange(event: MatRadioChange, idxAmb: number, idxTem: number) {
    if (event instanceof MatRadioChange) { //Aggiunto per risolvere il glitch di visualizzazione, prendeva un event non di tipo MatRadioChange

      this.ambiti[idxAmb].tematiche[idxTem].risposte = [event.value];

      this.ambiti[idxAmb].tematiche[idxTem].opzioni.forEach((opt, idx) => {
        if (opt.chiave !== event.value) {
          this.ambiti[idxAmb].tematiche[idxTem].opzioni[idx].sottoRisposte = [];
        }
      })

      //risposte
      this._updateSwiperHeight();
    }
  }

  cambioCheckOpzione(event: any, tematica: Tematica, opz: any) {

    if (!tematica.risposte) {
      tematica.risposte = [];
    }

    const indexRisp = tematica.opzioni.findIndex(opzList => opzList.chiave === opz.chiave);

    const idx = tematica.risposte.indexOf(opz.chiave);
    const minLvRisp = (tematica.minLivelloRispostaAutomatica ?? 3) - 1;

    if (event.checked) {
      // CHECKATO

      if (idx < 0) {
        tematica.risposte.push(opz.chiave);
      }

      if (indexRisp < minLvRisp) {
        // prime due risposte, dececkano tutto
        for (let i = 0; i < tematica.opzioni?.length; i++) {

          const opzPrec = tematica.opzioni[i];

          if (opzPrec.chiave !== opz.chiave) {
            const idxDaDecheck = tematica.risposte.indexOf(opzPrec.chiave);
            if (idxDaDecheck >= 0) {
              tematica.risposte.splice(idxDaDecheck, 1);
            }
          }
        }
      } else {

        // Devono essere checkate anche le checkbox prima;
        // Si ciclano tutte le opzioni della tematica fino a che 
        // non si trova l'opzione checkata da interfaccia.
        for (let i = 0; i < tematica.opzioni?.length; i++) {

          const opzPrec = tematica.opzioni[i];

          if (i < minLvRisp) {
            // le prime due si decheckano automaticamente
            const idxDaDecheck = tematica.risposte.indexOf(opzPrec.chiave);
            if (idxDaDecheck >= 0) {
              tematica.risposte.splice(idxDaDecheck, 1);
            }
            continue;
          }

          if (opzPrec.chiave !== opz.chiave) {
            if (tematica.risposte.indexOf(opzPrec.chiave) < 0) {
              tematica.risposte.push(opzPrec.chiave);
            }
          } else {
            // Trovata l'opzione checkata, si esce
            break;
          }
        }
      }

    } else {
      // DE-CHECKATO

      if (idx >= 0) {
        tematica.risposte.splice(idx, 1);
      }

      if (indexRisp < minLvRisp) {
        // prime due risposte, Non si fa niente
      } else {
        // Devono essere de-checkate anche le checkbox prima;
        // Si ciclano tutte le opzioni e si de-checkano tutte quelle
        // successive a quella de-checkata.
        let trovata = false;
        for (let i = 0; i < tematica.opzioni?.length; i++) {

          const opzPrec = tematica.opzioni[i];

          if (opzPrec.chiave !== opz.chiave) {
            if (trovata) {
              // Era stata trovata la check de-cehckata da interfaccia, da ora in avanti si de-checka tutto
              const idxDaDecheck = tematica.risposte.indexOf(opzPrec.chiave);
              if (idxDaDecheck >= 0) {
                tematica.risposte.splice(idxDaDecheck, 1);
              }
            }
          } else {
            // Trovata l'opzione checkata, si segna e si prosegue
            trovata = true;
          }
        }
      }
    }

    this._updateSwiperHeight();

  }

  sottoOpzAzKpiChecked() {
    this._updateSwiperHeight();
  }

  cambioCheckSottoOpzione(event: any, opzSec: any) {
    opzSec.checked = event.checked;
  }

  checkSotto(sottoRisposte: string[] | undefined, chiave: string) {
    const isRisposta = sottoRisposte?.find((risp) => {
      return risp === chiave;
    })

    if (isRisposta) {
      return true;
    } else {
      return false;
    }
  }

  onSlideChange() {

    this.indexAmbitoCorrente = this.swiperAmbiti.nativeElement.swiper.realIndex;

    this.indexTematicaCorrente = this.swiperTematiche.get(this.indexAmbitoCorrente)?.nativeElement.swiper.realIndex || 0;

    const passateTema = this.ambiti.slice(0, this.indexAmbitoCorrente).map((amb) => (amb.tematiche.length)).reduce((pre, curr) => {
      return pre + curr;
    }, 0);

    this.currentSlideIndex = passateTema + this.indexTematicaCorrente;

    this._updateSwiperHeight();

  }

  async avanti() {

    if (this.currentSlideIndex + 1 === this.totTematiche) {

      const dialog = await this.utilityService.openDialog({
        titolo: 'Confermare dati inseriti?',
        descrizione: 'Non sarà più possibile modificarli',
        bottoni: [
          {
            nome_btn: 'No',
            id_btn: 'N'
          },
          {
            nome_btn: 'Si',
            id_btn: 'S'
          },
        ]
      });

      const valDialog = await firstValueFrom(dialog.beforeClosed());
      if (valDialog === 'N') {
        return;
      }


      this.compilazione.stato = 'PUBBLICATO';
    }

    this.compilazione.tipo = 'AUTOVALUTAZIONE';

    if (!this._salva) {
      console.warn('NESSUN METODO SALVATAGGIO PASSATO');
      return;
    }


    this._salva(
      this.idAutovalutazioneCompilata,
      this.compilazione
    ).subscribe({
      next: (esito) => {


        if (esito) {
          const totTematiche = this.ambiti[this.swiperAmbiti.nativeElement.swiper.realIndex].tematiche.length;

          if ((this.currentSlideIndex + 1) === this.totTematiche) {
            this.completato.emit(true);
          } else {

            if (totTematiche === ((this.swiperTematiche.get(this.swiperAmbiti.nativeElement.swiper.realIndex)?.nativeElement.swiper.realIndex || 0) + 1)) {

              this.swiperAmbiti.nativeElement.swiper.slideNext();
            } else {

              this.swiperTematiche.get(this.swiperAmbiti.nativeElement.swiper.realIndex)?.nativeElement.swiper.slideNext();
            }
          }

        }

      },
      error: (err) => {

        console.error('ERRORE PUBBLICAZIONE COMPILAZIONE : ', err);

        this.utilityService.openDialog({
          titolo: 'Salvataggio non riuscito',
          descrizione: err?.error?.message || 'Errore nel salvataggio',
          bottoni: [{ nome_btn: 'Chiudi' }]
        });
      }
    });

  }

  indietro() {

    if (this.currentSlideIndex === 0) {
      this.completato.emit(true);
    }

    if ((this.swiperTematiche.get(this.swiperAmbiti.nativeElement.swiper.realIndex)?.nativeElement.swiper.realIndex || 0) === 0) {

      this.swiperAmbiti.nativeElement.swiper.slidePrev();
      this.swiperTematiche.get(this.swiperAmbiti.nativeElement.swiper.realIndex)!.nativeElement.swiper.slideTo(this.swiperTematiche.get(this.swiperAmbiti.nativeElement.swiper.realIndex)!.nativeElement.swiper?.slides.length - 1);

    } else {
      this.swiperTematiche.get(this.swiperAmbiti.nativeElement.swiper.realIndex)?.nativeElement.swiper.slidePrev();
    }

  }

  get ambiti(): Ambito[] {
    return this.compilazione?.ambiti || [];
  }

  private _updateSwiperHeight() {
    setTimeout(() => {
      this.swiperTematiche.forEach((swiper) => {
        swiper.nativeElement.swiper.update();
      });
      this.swiperAmbiti.nativeElement.swiper.update();
    }, 100);
  }
}
