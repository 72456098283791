import { Component, OnDestroy, OnInit, Output, ViewChild, forwardRef, EventEmitter, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CKEditorComponent, ChangeEvent } from '@ckeditor/ckeditor5-angular';


import translations from 'ckeditor5/translations/it.js';

import {

	ClassicEditor,
	AccessibilityHelp,
	Alignment,
	Autosave,
	BalloonToolbar,
	Bold,
	Essentials,
	FontBackgroundColor,
	FontColor,
	FontSize,
	GeneralHtmlSupport,
	Indent,
	IndentBlock,
	Italic,
	Link,
	List,
	Paragraph,
	PasteFromOffice,
	SelectAll,
	Underline,
	Undo,
	type EditorConfig
} from 'ckeditor5';

@Component({
	selector: 'app-editor',
	templateUrl: './editor.component.html',
	styleUrls: ['./editor.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => EditorComponent),
			multi: true
		}
	]
})
export class EditorComponent implements ControlValueAccessor {
	@ViewChild(CKEditorComponent) editorComponent!: CKEditorComponent;

	@Output()
	cambioValoreEditor = new EventEmitter;

	/**
	 * Serve per rimuovere/disabilitare uno o più plugin dell'editor.
	 * I plugin inclusi (e quindi disattivabili alla bisogna) sono visibili in ckeditor.ts
	 */
	private _removePlugins: string[] = [];

	@Input()
	set removePlugins(plugins: string[]) {
		this._removePlugins = plugins || [];
	}

	get removePlugins() {
		return this._removePlugins;
	}

	public isLayoutReady = false;
	public Editor = ClassicEditor;
	public config: EditorConfig = {}; // CKEditor needs the DOM tree before calculating the configuration.

	value = '';
	disabled = false;
	onChange: any = () => { };
	onTouched: any = () => { };

	writeValue(value: any): void {
		if (this.value !== value) {
			this.value = value;
		}
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	setDisabledState?(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}

	changed(event: ChangeEvent) {
		this.onChange(this.editorComponent.editorInstance?.data.get());
		this.cambioValoreEditor.emit(this.editorComponent.editorInstance?.data.get())
	}


	ngOnInit() {

		this.config = {
			toolbar: {
				items: [
					'undo',
					'redo',
					'|',
					'bold',
					'italic',
					'underline',
					'|',
					'fontSize',
					'fontColor',
					'fontBackgroundColor',
					'|',
					'alignment',
					'|',
					'bulletedList',
					'numberedList',
					'outdent',
					'indent',
					'|',
					'link'
				],
				shouldNotGroupWhenFull: false
			},
			plugins: [
				AccessibilityHelp,
				Alignment,
				Autosave,
				BalloonToolbar,
				Bold,
				Essentials,
				FontBackgroundColor,
				FontColor,
				FontSize,
				GeneralHtmlSupport,
				Indent,
				IndentBlock,
				Italic,
				Link,
				List,
				Paragraph,
				PasteFromOffice,
				SelectAll,
				Underline,
				Undo
			],
			balloonToolbar: ['bold', 'italic', '|', 'link', '|', 'bulletedList', 'numberedList'],
			fontSize: {
				options: [10, 12, 14, 'default', 18, 20, 22],
				supportAllValues: true
			},
			htmlSupport: {
				allow: [
					{
						name: /^.*$/,
						styles: true,
						attributes: true,
						classes: true
					}
				]
			},
			language: 'it',
			link: {
				addTargetToExternalLinks: true,
				defaultProtocol: 'https://',
				decorators: {
					toggleDownloadable: {
						mode: 'manual',
						label: 'Downloadable',
						attributes: {
							download: 'file'
						}
					}
				}
			},
			placeholder: 'Type or paste your content here!',
			translations: [translations],
			removePlugins: this._removePlugins || []
		}
	}

}
