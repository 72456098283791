import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Filters, FnCaricamentoDati, SortBy } from 'src/app/components/lista-tabellare/classes/lista-tabellare-data-source';
import { BottoniListaEvent, Colonna, Filtri, ListaTabellareComponent } from 'src/app/components/lista-tabellare/lista-tabellare.component';
import { MaterialitaService } from 'src/app/services/materialita/materialita.service';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { DialogCreaMaterialitaComponent } from './dialog/dialog-crea-materialita/dialog-crea-materialita.component';
import { Azienda, AziendaService } from 'src/app/services/azienda/azienda.service';
import { map, Subscription, switchMap, take } from 'rxjs';
import { UtenteService } from 'src/app/services/utente/utente.service';
import { SpinnerOverlayComponent } from 'src/app/components/spinner-overlay/spinner-overlay.component';
import { SettoriService } from 'src/app/services/settori/settori.service';
import { LicenzeService } from 'src/app/services/licenze/licenze.service';

@Component({
  selector: 'app-materialita',
  templateUrl: './materialita.component.html',
  styleUrls: ['./materialita.component.scss']
})
export class MaterialitaComponent {
  @ViewChild('tabella') tabella!: ListaTabellareComponent;
  @ViewChild(SpinnerOverlayComponent) spinnerOver!: SpinnerOverlayComponent;

  private _subAziendaCorrente: Subscription | undefined;

  private azienda: Azienda | undefined = undefined;

  public filtri: Filtri[] = [
    {
      titolo: 'Anno',
      forControlName: 'anno',
      input: 'text',
    },
    {
      forControlName: 'settore',
      input: 'multiple-option',
      titolo: 'Settore',
      fnDatiOption: () => (this.settoriService.getSettori(0, 1000)).pipe(map((value) => value?.content || [])),
      descValueOption: 'titolo',
      idValueOption: 'id',
      riempi: true
    }
    /*     {
          titolo: 'Cognome',
          forControlName: 'cognome',
          input: 'text',
        },
        {
          titolo: 'Email',
          forControlName: 'email',
          input: 'text',
        }, */
  ];

  public colonne: { [key: string]: Colonna } = {
    anno: {
      title: 'Anno',
      value: 'anno',
      sortable: true
    },
    settore: {
      title: 'Settore',
      sortable: true,
      value: (record: any) => (record?.settori && record?.settori![0]?.titolo),
    },
    surveyInviate: {
      title: 'Survey Inviate',
      sortable: true,
      value: (record: any) => (record?.surveyInviate?.count + '/' + record?.surveyInviate?.tot),
    },
    surveyCompilate: {
      title: 'Survey Compilate',
      sortable: true,
      value: (record: any) => (record?.surveyCompilate?.count + '/' + record?.surveyCompilate?.tot),
    },
    stato: {
      title: 'Stato',
      sortable: true,
      value: (record: any) => (record?.dataUltimaConferma ? 'Completata' : 'Non completata'),
    },
    dataUltimaConferma: {
      title: 'Ultima Conferma',
      sortable: true,
      value: (record: any) => (record?.dataUltimaConferma ? record?.dataUltimaConferma : ''),
    },

    modifica: {
      type: 'button',
      buttonIcon: this.isPermessoSoloRead() ? 'visibility' : 'edit',
      title: this.isPermessoSoloRead() ? 'Visualizza' : 'Modifica',
      buttonId: 'modifica',
      buttonMostraSempre: true,
      sortable: false,
    },

    elimina: {
      type: 'button',
      buttonIcon: 'delete',
      title: 'Elimina',
      buttonId: 'elimina',
      nascondiButton: (record: any) => this.nascondiBottoneElimina(record),
      buttonMostraSempre: true
    },
  };

  constructor(
    private settoriService: SettoriService,
    public dialog: MatDialog,
    private utilityService: UtilityService,
    private materialitaService: MaterialitaService,
    private aziendaService: AziendaService,
    public utenteService: UtenteService,
    private licenzeService: LicenzeService,
  ) { }


  nascondiBottoneElimina(record: any) {
    if (this.utenteService.infoUtente?.isDeveloper) {
      return false; //non nascondere
    }
    if ((record.stato === 'PUBBLICATO' || !this.utenteService.isPermessoAttivo('DELETE_MATERIALITA'))) {
      return true //nascondere
    }
    return false
  }


  isPermessoSoloRead() {
    const isEdit = this.utenteService.isPermessoAttivo('EDIT_MATERIALITA');
    const isCreate = this.utenteService.isPermessoAttivo('CREATE_MATERIALITA')
    return (!isEdit && !isCreate)
  }

  ngAfterViewInit(): void {
    this._subAziendaCorrente = this.aziendaService.aziendaCorrente.subscribe((azienda) => {

      let cambiato = false;

      if (this.azienda && azienda && this.azienda.id !== azienda.id) {
        cambiato = true;
      }

      this.azienda = azienda;

      if (cambiato) {
        this.tabella?.caricaDati();
      }

    });
  }

  ngOnDestroy(): void {
    this._subAziendaCorrente?.unsubscribe();
  }

  creaNuovo(record?: any) {
    if (!record) {
      this.spinnerOver.show();
      this.licenzeService.getUserHasLicense('Materialità').subscribe({
        next: (esito) => {
          this.spinnerOver.hide();
          this.apriDialogCreMaterialita(record);
        },
        error: (err) => {
          this.spinnerOver.hide();
          this.utilityService.openDialog({
            titolo: 'Attenzione',
            descrizione: err?.error?.message,
            bottoni: [{ nome_btn: 'Ok' }]
          });
        }
      });
    } else {
      this.apriDialogCreMaterialita(record);
    }
  }


  private apriDialogCreMaterialita(record: any) {
    const dialogCreaAmbito = this.dialog.open(DialogCreaMaterialitaComponent, {
      data: {
        materialita: record,
      },
      panelClass: 'dialog-container',
      disableClose: false,
      width: '100%',
      height: '99%',
      maxHeight: '99%',
      autoFocus: false,
    });

    dialogCreaAmbito.afterClosed().subscribe((result) => {

      this.tabella.caricaDati();
    });
  }


  public fnCaricamentoDati: FnCaricamentoDati = (
    page: number,
    pageSize: number,
    ricerca?: string,
    filters?: Filters[],
    sortBy?: SortBy[]
  ) => {
    return this.materialitaService.getMaterialita(page, pageSize, ricerca, filters, sortBy);
  };

  public bottoniListaClick(event: BottoniListaEvent) {
    switch (event.id) {
      case 'modifica':
        this.creaNuovo(event.data);
        break;
      case 'elimina':
        this._elimina(event.data);
        break;
      default:
        console.warn('ATTENZIONE: bottone ' + event.id + ' non riconosciuto');
    }
  }

  private _elimina(materialita: any) {
    let descrizioneElimina: string;
    let titolo: string;
    descrizioneElimina = '<div> <p><strong> Si prega di notare che questa operazione eliminerà anche tutte le compilazioni raccolte associate.</strong></p> <ul> <li>L’eliminazione è definitiva e <strong>NON può essere annullata</strong>.</li> <li>Tutte le informazioni e le compilazioni raccolte saranno perse <strong>permanentemente</strong>.</li></ul><p><strong>Sei sicuro di voler continuare?</strong></p>'
    titolo = 'Eliminazione Dati Irreversibile'
    /* if (materialita?.dataUltimaConferma) {
      descrizioneElimina = '<div> <p> Si prega di notare che questa operazione eliminerà anche tutte le compilazioni raccolte associate.</p> <ul> <li>L’eliminazione è definitiva e <strong>NON può essere annullata</strong>.</li> <li>Tutte le informazioni e le compilazioni raccolte saranno perse <strong>permanentemente</strong>.</li></ul><p>Sei sicuro di voler continuare?</p>'
      titolo = 'Eliminazione Dati Irreversibile'
    } else {
      descrizioneElimina = 'Confermi di eliminare l\'analisi di materialità?'
      titolo = 'Elimina'
    } */

    this.utilityService.openDialog({
      titolo: titolo,
      descrizione: descrizioneElimina,
      fontWeight: '500',
      bottoni: [
        {
          nome_btn: 'NO',
          background_color: 'var(--mat-color-accent)',
        },
        {
          nome_btn: 'SI',
          background_color: 'var(--mat-color-primary)',
          handler: () => {
            this.spinnerOver.show();
            this.materialitaService.deleteMaterialita(materialita.id).subscribe(
              {
                next: (result: any) => {
                  this.tabella.caricaDati();
                  this.spinnerOver.hide();
                },
                error: (err: any) => {
                  console.error(err);
                  this.utilityService.openDialog({
                    titolo: 'Attenzione',
                    descrizione: err?.error?.message || 'Eliminazione non riuscita.',
                    bottoni: [
                      {
                        nome_btn: 'Chiudi', handler: () => {
                          this.tabella.caricaDati();
                          this.spinnerOver.hide();
                        }
                      }]
                  });
                  this.spinnerOver.hide();
                }
              }
            );
          }
        }]
    });
  }
}
