import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormGroup, FormControl, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Observable, Subscription, catchError, firstValueFrom, forkJoin, map, of, switchMap, throwError } from 'rxjs';
import { DialogAnteprimaComponent } from 'src/app/page/configurazioni/cfg-autovalutazioni/dialog/dialog-anteprima/dialog-anteprima.component';
import { DialogCreaCfgTematicaComponent } from 'src/app/page/configurazioni/cfg-tematiche/dialog/dialog-crea-cfg-tematica/dialog-crea-cfg-tematica.component';
import { Ambito, AmbitiService } from 'src/app/services/ambiti/ambiti.service';
import { AutovalutazioniService } from 'src/app/services/autovalutazioni/autovalutazioni.service';
import { Settore, SettoriService, SottoSettore } from 'src/app/services/settori/settori.service';
import { TematicheService, Tematica } from 'src/app/services/tematiche/tematiche.service';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { SpinnerOverlayComponent } from '../spinner-overlay/spinner-overlay.component';
import { MaterialitaService } from 'src/app/services/materialita/materialita.service';
import { SintesiService } from 'src/app/services/sintesi/sintesi.service';
import { NgScrollbar } from 'ngx-scrollbar';
import { BilancioService } from 'src/app/services/bilancio/bilancio.service';
import { Filters } from '../lista-tabellare/classes/lista-tabellare-data-source';
import { MatSelectChange } from '@angular/material/select';
import { MatRadioChange } from '@angular/material/radio';
import { PolicyService } from 'src/app/services/policy/policy.service';
import { PianoService } from 'src/app/services/piano/piano.service';
import { TemaService } from 'src/app/services/tema/tema.service';
import { Utente, UtenteService } from 'src/app/services/utente/utente.service';

@Component({
  selector: 'app-cfg-questionario',
  templateUrl: './cfg-questionario.component.html',
  styleUrls: ['./cfg-questionario.component.scss']
})
export class CfgQuestionarioComponent {

  @ViewChild(SpinnerOverlayComponent) spinnerOver!: SpinnerOverlayComponent;
  @ViewChildren('scrollDisponibili') scrollDispobibili!: QueryList<NgScrollbar>;
  @ViewChildren('scrollSelezionate') scrollSelezionate!: QueryList<NgScrollbar>;

  public qstDisabilitato = false;

  private _cfgQuestionario: any | undefined;

  private _nascondiTitolo = false;

  public ambitiLocked: { [key: string]: boolean } = {};

  private salvaSettoreAutovalutazione: Settore | undefined;

  @Input()
  set cfgQuestionario(cfg: any) {

    const variato = this._cfgQuestionario != cfg;

    this._cfgQuestionario = cfg;

    if (variato) {
      if (this.cfgQuestionario) {

        if (this.cfgQuestionario.id) {
          this.formCreaCfgQuestionario.get('id')?.setValue(this.cfgQuestionario.id);
        }

        if (this._cfgQuestionario?.stato === 'PUBBLICATO' || this._cfgQuestionario?.stato === 'ARCHIVIATO' || this.isPermessoSoloRead) { // Si blocca sia per Pubblicati che per Archiviati
          this.formCreaCfgQuestionario.disable();
          this.qstDisabilitato = true;
        } else {
          this.qstDisabilitato = false;
        }

        this.ambitiSelezionati = this.cfgQuestionario?.ambiti?.length ? this.cfgQuestionario?.ambiti : []; // Ambiti da questionario 

        const settore = this.cfgQuestionario?.settori?.length ? this.cfgQuestionario?.settori[0] : undefined;
        this.formCreaCfgQuestionario.get('settori')?.patchValue(settore);

        const sottoSettori = this.cfgQuestionario?.sottoSettori?.length ? this.cfgQuestionario?.sottoSettori : null;
        this.formCreaCfgQuestionario.get('sottoSettori')?.patchValue(sottoSettori);

        this.formCreaCfgQuestionario.get('titolo')?.setValue(this.cfgQuestionario.titolo);

        // Mergio gli ambiti disponibili con quelli derivati dal questionario. Questo per visualizzare tutti gli ambiti nelle TAB
        // e viene rimossa la selezione dei ambiti. 
        if (this.tipoQuestionario !== 'AUTOVALUTAZIONE' && this._cfgQuestionario?.stato !== 'PUBBLICATO' && this._cfgQuestionario?.stato !== 'ARCHIVIATO') {

          const mergeAmbiti = this.arrayAmbiti.filter(elem1 => !this.ambitiSelezionati.some(elem2 => elem1.id === elem2.id));
          this.ambitiSelezionati = this.ambitiSelezionati.concat(mergeAmbiti);

        }

        if (this.ambitiSelezionati?.length) {
          this.formCreaCfgQuestionario.get('ambiti')?.patchValue(this.tipoQuestionario !== 'AUTOVALUTAZIONE' ? this.ambitiSelezionati : this.cfgQuestionario?.ambiti);

          this.cfgQuestionario?.ambiti?.forEach((ambito: any) => {

            // Mi creo una mappa con gli id ambiti che sono locked per poi disabilitarli nella select
            if (ambito?.locked) {
              this.ambitiLocked[ambito?.id] = true;
            }

            this.arrayTematicheSel[ambito.id] = ambito.tematiche;
            this._getTematicheDisponibili(ambito.id);
          })
        }

        this.formCreaCfgQuestionario.get('chiave')?.setValue(this.cfgQuestionario.chiave);

        this.formCreaCfgQuestionario.get('tipoAutovalutazione')?.setValue(this.cfgQuestionario.tipoAutovalutazione);

        if (this.tipoQuestionario === 'SURVEY') {
          this.formCreaCfgQuestionario.get('tipoPunteggio')?.setValue(this.cfgQuestionario.tipoPunteggio);
          this.formCreaCfgQuestionario.get('scadenzaSurvey')?.setValue(this.cfgQuestionario.scadenzaSurvey);
        }


      }
    }

  }

  get cfgQuestionario() {
    return this._cfgQuestionario;
  }

  get stato() {
    return this.cfgQuestionario?.stato || 'BOZZA';
  }

  set stato(stato: string) {
    this.cfgQuestionario.stato = stato;
  }

  get id() {
    return this.cfgQuestionario.id || 'BOZZA';
  }

  private _tipoQuestionario: 'AUTOVALUTAZIONE' | 'SURVEY' | 'SINTESI' | 'BILANCIO' | 'POLICY' | 'PIANO' | undefined;

  @Input()
  set tipoQuestionario(tipo: 'AUTOVALUTAZIONE' | 'SURVEY' | 'SINTESI' | 'BILANCIO' | 'POLICY' | 'PIANO' | undefined) {
    this._tipoQuestionario = tipo;
  }

  get tipoQuestionario() {
    return this._tipoQuestionario;
  }

  /* Solo per tipo survey materialità */
  private _idMaterialita: string | undefined;

  @Input()
  set idMaterialita(tipo: string | undefined) {
    this._idMaterialita = tipo;
  }

  get idMaterialita(): string | undefined {
    return this._idMaterialita;
  }

  private _isPermessoSoloRead = false;
  @Input()
  set isPermessoSoloRead(isPermessoSoloRead: boolean) {
    this._isPermessoSoloRead = isPermessoSoloRead;
  }
  get isPermessoSoloRead() {
    return this._isPermessoSoloRead;
  }


  @Input()
  /* titoloSeparatore */
  set nascondiTitolo(nascondiTitolo: boolean) {
    this._nascondiTitolo = nascondiTitolo;
  }
  get nascondiTitolo() {
    return this._nascondiTitolo;
  }

  /* Solo per tipo survey sintesi */
  private _idSostenibilita: string | undefined;
  @Input()
  set idSostenibilita(tipo: string | undefined) {
    this._idSostenibilita = tipo;
  }

  get idSostenibilita(): string | undefined {
    return this._idSostenibilita;
  }

  get numeroTematicheSel(): number {
    const numeroTematiche = Object.keys(this.arrayTematicheSel)
      .filter(key => this.arrayTematicheSel[key].length > 0); // Verifica se l'array ha almeno un elemento
    return numeroTematiche.length;

  }

  @Output()
  public salvato = new EventEmitter<void>();

  @Output()
  public pubblicato = new EventEmitter<void>();

  public arrayAmbiti: Ambito[] = [];
  public arraySettori: Settore[] = [];
  public arraySottoSettori: SottoSettore[] | undefined = [];
  public arrayTematicheSel: { [key: string]: any } = {};
  public arrayTematicheDisponibili: { [key: string]: any } = {};
  public loadingTemDisp: { [key: string]: any } = {};

  public ambitiSelezionati: Ambito[] = [];

  public formCreaCfgQuestionario = new FormGroup({
    id: new FormControl<string | undefined>(undefined),
    chiave: new FormControl<string | undefined>(undefined),
    titolo: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required, UtilityService.blankValidator],
    }),
    ambiti: new FormControl<Ambito[]>([], {
      nonNullable: true,
      validators: [Validators.required],
    }),
    settori: new FormControl<Settore | undefined>(undefined, {
      nonNullable: true,
      validators: [this._validatoreSettori()],
    }),
    sottoSettori: new FormControl<SottoSettore[]>([], {
      nonNullable: true
    }),
    /* SOLO AUTOVALUTAZIONI */
    tipoAutovalutazione: new FormControl<'GENERICO' | 'SETTORE' | undefined>(undefined),
    /* SOLO SURVEY MATERIALITA */
    tipoPunteggio: new FormControl<string>('BASE_5', {
      nonNullable: true,
      validators: [this._requiredSeSurvey()]
    }),
    scadenzaSurvey: new FormControl<Date | undefined>(undefined, {
      nonNullable: true,
      validators: [this._requiredSeSurvey()]
    })
  });

  private _validatoreSettori(): ValidatorFn {
    return (formControl: AbstractControl) => {
      if ((this.tipoQuestionario === 'SINTESI' || (this.tipoQuestionario === 'AUTOVALUTAZIONE' && formControl.parent?.get('tipoAutovalutazione')?.value === 'SETTORE')) && !formControl.value) {
        return { required: true };
      }

      return null;
    }
  }

  /**
   * Campo obbligatorio solo se configurazione survey
   * @returns 
   */
  private _requiredSeSurvey(): ValidatorFn {
    return (formControl: AbstractControl) => {
      if (this.tipoQuestionario === 'SURVEY' && !formControl.value) {
        return { required: true };
      }

      return null;
    }
  }

  private readonly _subTema: Subscription | undefined;
  public isUtenteGo: boolean = false;
  constructor(
    private readonly ambitiService: AmbitiService,
    private readonly settoriService: SettoriService,
    private readonly tematicheService: TematicheService,
    private readonly utilityService: UtilityService,
    private readonly autovalutazioniService: AutovalutazioniService,
    private readonly materialitaService: MaterialitaService,
    private readonly dialog: MatDialog,
    private readonly sintesiService: SintesiService,
    private readonly bilancioService: BilancioService,
    private readonly policyService: PolicyService,
    private readonly pianoService: PianoService,
    private readonly temaService: TemaService,
    private readonly utenteService: UtenteService,
  ) {
    this._subTema = temaService.temaUpdated.subscribe(tipoInstallazione => {
      if (tipoInstallazione === 'SOSTENIBILE_GO') {
        this.isUtenteGo = true;
      } else {
        this.isUtenteGo = false;
      }
    })
  }

  ngOnInit() { }

  ngAfterViewInit(): void {

    this.datiInizialiCfgQuestionario();

    if (this.tipoQuestionario === 'AUTOVALUTAZIONE' && !this.formCreaCfgQuestionario.get('tipoAutovalutazione')?.value) {
      this.formCreaCfgQuestionario.get('ambiti')?.disable();
    }

    this.formCreaCfgQuestionario.get('sottoSettori')?.valueChanges.subscribe((sottoSettori: SottoSettore[]) => {
      // Cambiati i sottosettori, refresha le tematiche disponibili
      this.ambitiSelezionati.forEach((ambito) => {
        this._getTematicheDisponibili(ambito.id);
      });
    });

    this.formCreaCfgQuestionario.get('ambiti')?.valueChanges.subscribe((ambiti: Ambito[]) => {

      const rimossi = this.ambitiSelezionati.filter((ambitoSel) => {
        return !ambiti.find((ambito) => ambito.id === ambitoSel.id)
      });

      const aggiunti = ambiti.filter((ambito) => {
        return !this.ambitiSelezionati.find((ambitoSel) => ambito.id === ambitoSel.id)
      });


      /*  if (this.tipoQuestionario !== 'AUTOVALUTAZIONE') {
 
         const ambConTematiche = this.ambitiSelezionati.filter(ambito => ambito?.tematiche?.length > 0);
 
         const ambSenzaTematiche = this.ambitiSelezionati.filter(ambito => !ambito?.tematiche?.length);
 
         const ambSenzaTematicheOrdinati = ambSenzaTematiche.sort((a, b) => {
           const indexA = a.index ?? Infinity; // Assegna Infinity se l'indice non è definito
           const indexB = b.index ?? Infinity;
           return indexA - indexB;
         }); // Ordina per indice
 
         this.ambitiSelezionati = [...ambConTematiche, ...ambSenzaTematicheOrdinati];
 
       } else { */

      this.ambitiSelezionati = this.ambitiSelezionati
        .filter((ambitoSel) => { // Rimuove ambiti non selezionati
          return ambiti.find((ambito) => ambito.id === ambitoSel.id);
        }).concat(aggiunti) // Aggiunge i nuovi
        .sort((a, b) => {
          let indexA = a.index == null ? Infinity : a.index;
          let indexB = b.index == null ? Infinity : b.index;
          return indexA - indexB;
        }); //ordino l'array per avere le tab ordinate
      /*     } */

      this.ambitiSelezionati.forEach((ambito: Ambito) => {
        if (!this.arrayTematicheSel[ambito.id]) {
          this._getTematicheDisponibili(ambito.id);
          this.arrayTematicheSel[ambito.id] = ambito.tematiche || [];
        }
      });

    });
  }

  ngOnDestroy(): void {
    this._subTema?.unsubscribe();
  }

  /**
   * Dati iniziali configurazione tematiche questionario 
   * @param idSettore 
   */
  public datiInizialiCfgQuestionario(idSettore?: string) {


    if (this._cfgQuestionario?.tipoAutovalutazione === 'SETTORE' && !idSettore) { //sono in modifica
      idSettore = this._cfgQuestionario?.settori[0]?.id;
    }

    this.spinnerOver.show();


    let filtroAmbitoPerIdSettore: Filters = {
      values: [idSettore],
      operator: 'eq',
      chiave: 'idSettore'
    }

    forkJoin({
      ambiti: this.ambitiService.getAmbiti(0, 1000, '', idSettore ? [filtroAmbitoPerIdSettore] : [], [
        {
          chiave: 'index',
          sort: 'asc',
        },
        {
          chiave: 'titoloBreve',
          sort: 'asc'
        }]),
      settori: this.settoriService.getSettori(0, 1000, '', [], [{
        chiave: 'titolo',
        sort: 'asc'
      }])
    }).subscribe({
      next: (risp) => {

        this.arraySettori = risp?.settori?.content || [];

        if (idSettore) {
          const settore = this.arraySettori.find((settore) => settore.id === idSettore);
          if (settore) {
            this.arraySottoSettori = settore.sottoSettori || [];
          }
        }

        this.arrayAmbiti = risp?.ambiti?.content || [];

        this.arrayAmbiti = this.arrayAmbiti.sort((a, b) => {
          let indexA = a.index == null ? Infinity : a.index; // Trasforma null o undefined in Infinity
          let indexB = b.index == null ? Infinity : b.index; // Trasforma null o undefined in Infinity
          return indexA - indexB; // Ordina per index, mettendo i null o undefined alla fine
        }); //ordino l'array per avere le tab ordinate

        this.spinnerOver.hide();
      },
      error: (err) => {
        console.error(err);

        this.spinnerOver.hide();

        this.utilityService.openDialog({
          titolo: 'Attenzione',
          descrizione: err?.error?.message || 'Errore nel recupero dei dati',
          bottoni: [{ nome_btn: 'Ok' }]
        })

      }
    })
  }

  /**
   * Cambio tipo di autovalutazione
   * @param event 
   * @returns 
   */
  async onSelectionChangeTipo(event: MatRadioChange) {

    if (this.formCreaCfgQuestionario.get('ambiti')?.value.length) {
      /*  if (this.ambitiSelezionati.length) { */
      const dialog = await this.utilityService.openDialog({
        titolo: 'Cambio Tipo Autovalutazione',
        descrizione: 'Una volta cambiato il tipo autovalutazione, rinizierai una nuova configurazione',
        bottoni: [
          {
            nome_btn: 'No',
            id_btn: 'N'
          },
          {
            nome_btn: 'Si',
            id_btn: 'S'
          },
        ]
      });

      const valDialog = await firstValueFrom(dialog.beforeClosed());
      if (valDialog === 'N') {
        if (event.value === 'GENERICO') {
          this.formCreaCfgQuestionario.get('tipoAutovalutazione')?.setValue('SETTORE')
        } else {
          this.formCreaCfgQuestionario.get('tipoAutovalutazione')?.setValue('GENERICO')
        }
        return;
      }
    }

    this.arrayTematicheSel = {};
    this.ambitiSelezionati = [];
    this.formCreaCfgQuestionario.get('settori')?.setValue(undefined)
    this.formCreaCfgQuestionario.get('sottoSettori')?.setValue([])
    this.formCreaCfgQuestionario.get('ambiti')?.setValue([])


    if (event.value === 'GENERICO') {
      this.datiInizialiCfgQuestionario()
      this.formCreaCfgQuestionario.get('ambiti')?.enable();

    } else if (event.value === 'SETTORE') {
      this.formCreaCfgQuestionario.get('ambiti')?.disable();
      this.formCreaCfgQuestionario.get('settori')?.enable();
      this.formCreaCfgQuestionario.get('sottoSettori')?.enable();
    }
  }

  /**
   * Cambio settore
   * @param event 
   * @returns 
   */
  async onSelectionChangeSettore(event: MatSelectChange) {

    const formQuest = this.formCreaCfgQuestionario;

    if (formQuest.get('ambiti')?.status === 'DISABLED') {
      formQuest.get('ambiti')?.enable();
    }

    if (formQuest.get('ambiti')?.value.length) {

      const dialog = await this.utilityService.openDialog({
        titolo: 'Cambio Settore',
        descrizione: 'Una volta cambiato settore, rinizierai una nuova configurazione',
        bottoni: [
          {
            nome_btn: 'No',
            id_btn: 'N'
          },
          {
            nome_btn: 'Si',
            id_btn: 'S'
          },
        ]
      });

      const valDialog = await firstValueFrom(dialog.beforeClosed());

      if (valDialog === 'N') {
        formQuest.get('settori')?.setValue(this.salvaSettoreAutovalutazione);
        return;
      }

    }

    this.salvaSettoreAutovalutazione = event.value;

    this.arraySottoSettori = this.salvaSettoreAutovalutazione?.sottoSettori;

    if (this.formCreaCfgQuestionario.get('sottoSettori') !== null) {
      this.formCreaCfgQuestionario.get('sottoSettori')!.setValue(
        this.formCreaCfgQuestionario.get('sottoSettori')!.value.filter(
          (sottoSettSel) => {
            return this.arraySottoSettori && sottoSettSel.id && !!this.arraySottoSettori.find(
              (sottoSett) => {
                return sottoSett.id === sottoSettSel.id;
              });
          })
      );
    }

    formQuest.get('ambiti')?.setValue([])
    this.arrayTematicheSel = {};
    this.ambitiSelezionati = [];
    this.datiInizialiCfgQuestionario(event?.value?.id)
  }

  drop(event: CdkDragDrop<{ descrizione: string; chiave: string; id: string }[]>, idAmbito: string, tipoDrop: string) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else if (tipoDrop === 'SELEZIONATI' && this.numeroTematicheSel >= 1 && this.isUtenteGo && this.tipoQuestionario === 'BILANCIO') {

      this.utilityService.opneSnackBar('Attenzione, devi scegliere una sola tematica su cui basare il tuo report', '', {
        duration: 2000,
        panelClass: ['red-snackbar']
      });
      return;

    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }

    setTimeout(() => {
      const scrollbarSelezionate = this.scrollSelezionate.find((item) =>
        item.nativeElement.id === idAmbito + '_sel'
      )
      if (scrollbarSelezionate) {
        scrollbarSelezionate.update();
      }
      const scrollbarDisponibili = this.scrollDispobibili.find((item) =>
        item.nativeElement.id === idAmbito + '_disp'
      )
      if (scrollbarDisponibili) {
        scrollbarDisponibili.update();
      }

    }, 0);
  }

  modifica(recordTematica: any) {


    const dialogCreaAmbito = this.dialog.open(DialogCreaCfgTematicaComponent, {
      data: {
        tematica: recordTematica,
      },
      panelClass: 'dialog-container',
      disableClose: false,
      width: '90%',
      height: '95%',
      maxHeight: '95%',
      autoFocus: false,
    });

    dialogCreaAmbito.afterClosed().subscribe((result) => {

    });
  }

  salvaInterno() {
    this.salva().subscribe();
  }

  public ctrSalva(): boolean {
    //DA PASSARE AMBITI NEI METODI DI SALVATAGGIO DELLO SWITCH
    const ambiti = this._gestioneAmbitiPreSalvataggio();
    if (!ambiti.length) {
      this.utilityService.opneSnackBar(' Selezionare almeno una tematica ', '', {
        duration: 2000,
        panelClass: ['red-snackbar']
      });
      return true;
    }
    return false;
  }

  salva(): Observable<any> {

    if (this.ctrSalva()) {
      return of(false);
    }

    switch (this._tipoQuestionario) {
      case 'AUTOVALUTAZIONE':
        return this._salvaAutovalutazione();

      case 'SURVEY':
        return this._salvaSurveyMaterialita();

      case 'SINTESI':
        return this._salvaSintesiSurvey();

      case 'BILANCIO':
        return this._salvaBilancioSurvey();

      case 'POLICY':
        return this._salvaPolicySurvey();
      case 'PIANO':
        return this._salvaPianoSurvey();

      default:
        return of();
    }

  }

  anteprimaAutovalutazione() {

    if (this.tipoQuestionario !== 'AUTOVALUTAZIONE') {
      console.error('IL QUESTIONARIO NON E\' UN\'AUTOVALUTAZIONE');
      return;
    }

    const ambTematiche = this.ambitiSelezionati.map((amb: Ambito) => {
      return Object.assign({}, amb, { tematiche: this.arrayTematicheSel[amb.id] });
    })



    if (!this.ambitiSelezionati?.length) {
      this.utilityService.openDialog({
        titolo: 'Attenzione',
        descrizione: 'Seleziona un ambito con tematica selezionata',
        bottoni: [{ nome_btn: 'Ok' }]
      })
      return;
    }

    const dialogFiltro = this.dialog.open(DialogAnteprimaComponent, {
      data: {
        ambito: ambTematiche
      },
      panelClass: 'dialog-container',
      disableClose: false,
      width: '100%',
      maxHeight: '95%',
      height: '95%',
      autoFocus: false,
    });

    dialogFiltro.afterClosed().subscribe((result) => {

    });
  }


  pubblicaAutovalutazione() {
    if (this.tipoQuestionario !== 'AUTOVALUTAZIONE') {
      console.error('IL QUESTIONARIO NON E\' UN\'AUTOVALUTAZIONE');
      return;
    }

    this._salvaAutovalutazione().subscribe({
      next: (esito) => {
        if (esito) {
          this._putPubblicaAutovalutazione(this.formCreaCfgQuestionario.get('id')?.value);
        } else {
          console.error('Autovalutazione non salvata');
        }
      },
      error: (err) => {
        console.error(err)
      }
    });

  }


  private _putPubblicaAutovalutazione(id: any) {
    this.autovalutazioniService.putPubblicaAutovalutazione(id).subscribe({
      next: (result: any) => {


        this.utilityService.opneSnackBar(' Autovalutazione pubblicata ', '', {
          duration: 2000,
          panelClass: ['success-snackbar']
        });

        this.pubblicato.emit();

      },
      error: (err: any) => {
        console.error('Errore :', err);

        this.utilityService.openDialog({
          titolo: 'Attenzione',
          descrizione: 'Errore pubblicazione' + (err?.error?.message ? ' : ' + err.error.message : ''),
          bottoni: [{ nome_btn: 'Ok' }]
        })
      }
    })
  }

  compareById(elemento1: any, elemento2: any): boolean {
    return elemento1?.id === elemento2?.id;
  }

  /**
   * Assegno il tipo alla tematica
   * @param ambiti lista ambiti
   */
  addTipoSuTematica(autovalutazione?: boolean) {
    this.ambitiSelezionati = this.ambitiSelezionati.map((ambito: any) => {
      ambito.tematiche.forEach((tematica: any) => {

        tematica.tipo = autovalutazione ? 'scelta_multipla' : 'scelta_multipla_valore';
      });
      return ambito;
    });

  };

  /**
   * Metodo salvataggio autovalutazione
   * @returns 
   */
  private _salvaAutovalutazione(): Observable<boolean> {
    this.ambitiSelezionati?.forEach((ambito: Ambito) => {
      ambito.tematiche = this.arrayTematicheSel[ambito.id];
    })

    if (this.formCreaCfgQuestionario.valid) {

      const id = this.formCreaCfgQuestionario.get('id')?.value;
      const tipoAutovalutazione = this.formCreaCfgQuestionario.get('tipoAutovalutazione')?.value;
      const chiave = this.formCreaCfgQuestionario.get('chiave')?.value || '';
      if (!tipoAutovalutazione) {
        this.utilityService.opneSnackBar('Nessun tipo selezionato', '', {
          duration: 2000,
          panelClass: ['red-snackbar']
        });
        return of(false);
      }

      let settori: Settore[] = [];
      let sottoSettori: SottoSettore[] = [];
      if (tipoAutovalutazione === 'SETTORE') {
        settori = this.formCreaCfgQuestionario.get('settori')?.value ? [(this.formCreaCfgQuestionario.get('settori')!.value as Settore)] : [];
        sottoSettori = this.formCreaCfgQuestionario.get('sottoSettori')?.value ? this.formCreaCfgQuestionario.get('sottoSettori')!.value : [];
      }

      // Aggiungo il tipo "scelta_singola" sulla tematica se sono in autovalutazione 
      this.addTipoSuTematica(true);

      if (id) {

        this.spinnerOver.show();
        return this.autovalutazioniService.putCfgAutovalutazione(
          id,
          this.formCreaCfgQuestionario.get('titolo')?.value || '',
          chiave,
          this.ambitiSelezionati || [],
          this.tipoQuestionario || '',
          tipoAutovalutazione,
          settori,
          sottoSettori,
          this.cfgQuestionario.stato
        ).pipe(
          map((result: any) => {


            this.utilityService.opneSnackBar('Aggiornamento effettuato', '', {
              duration: 2000,
              panelClass: ['success-snackbar']
            });

            this.salvato.emit();
            this.spinnerOver.hide();
            return true;

          }),
          catchError((err: any) => {
            console.error('Errore :', err);

            this.utilityService.openDialog({
              titolo: 'Attenzione',
              descrizione: err?.error?.message || 'Errore salvataggio',
              bottoni: [{ nome_btn: 'Ok' }]
            });
            this.spinnerOver.hide();
            return of(false);
          })
        );

      } else {
        this.spinnerOver.show();
        return this.autovalutazioniService.postCfgAutovalutazione(
          this.formCreaCfgQuestionario.get('titolo')?.value || '',
          this.ambitiSelezionati || [],
          this.tipoQuestionario || '',
          tipoAutovalutazione,
          settori,
          sottoSettori
        )
          .pipe(
            switchMap((result) => {


              if (result?.body) {
                this.formCreaCfgQuestionario.get('id')?.setValue(result.body);

                // Rifà la get per caricare la chiave
                return this.autovalutazioniService.getCfgAutovalutazioneById(result.body);
              } else {
                return throwError(() => 'Nessun identificativo autovalutazione');
              }

            }),
            map((result) => {


              if (result) {
                this.cfgQuestionario = result;
                this.formCreaCfgQuestionario.get('chiave')?.setValue(result.chiave);

                this.utilityService.opneSnackBar('Salvataggio effettuato ', '', {
                  duration: 2000,
                  panelClass: ['success-snackbar']
                });

                this.salvato.emit();
                this.spinnerOver.hide();
                return true;
              } else {
                this.utilityService.openDialog({
                  titolo: 'Attenzione',
                  descrizione: 'Errore salvataggio - nessun dato estratto',
                  bottoni: [{ nome_btn: 'Ok' }]
                });
                this.spinnerOver.hide();
                return false;
              }
            }),
            catchError((err) => {
              console.error('Errore :', err);

              this.utilityService.openDialog({
                titolo: 'Attenzione',
                descrizione: err?.error?.message || 'Errore salvataggio',
                bottoni: [{ nome_btn: 'Ok' }]
              });
              this.spinnerOver.hide();
              return of(false);
            })
          );
      }

    } else {
      Object.values(this.formCreaCfgQuestionario.controls).forEach(
        (control) => {
          control.markAsTouched();
        }
      );

      return of(false);
    }

  }


  /**
   * Metodo salvataggio Survey Materialità
   * @returns 
   */
  private _salvaSurveyMaterialita(): Observable<boolean> {

    const ambiti = this._gestioneAmbitiPreSalvataggio();

    const id = this.formCreaCfgQuestionario.get('id')?.value || [];
    const settori = this.formCreaCfgQuestionario.get('settori')?.value ? [(this.formCreaCfgQuestionario.get('settori')!.value as Settore)] : [];

    let sottoSettori: SottoSettore[] = this.formCreaCfgQuestionario.get('sottoSettori')?.value ? this.formCreaCfgQuestionario.get('sottoSettori')!.value : [];


    const titolo = this.formCreaCfgQuestionario.get('titolo')?.value || '';
    const tipoPunteggio = this.formCreaCfgQuestionario.get('tipoPunteggio')?.value || '';
    const scadenzaSurvey = this.formCreaCfgQuestionario.get('scadenzaSurvey')?.value || '';

    if (this.idMaterialita) {

      const idSurvey = this.formCreaCfgQuestionario.get('id')?.value

      if (idSurvey) {

        return this.materialitaService.putSurvey(this.idMaterialita, idSurvey, ambiti, settori, sottoSettori, titolo, tipoPunteggio, scadenzaSurvey, this.cfgQuestionario.stato).pipe(
          map((survey) => {

            this.cfgQuestionario = survey;

            this.salvato.emit();

            return true;

          }),
          catchError((err) => {
            console.error('Errore durante il salvataggio', err);

            this.utilityService.openDialog({
              titolo: 'Attenzione',
              descrizione: 'Errore nel salvataggio' + (err?.error?.message ? ' : ' + err.error.message : ''),
              bottoni: [{ nome_btn: 'Ok' }]
            });

            return of(false);

          })
        );

      } else {
        return this.materialitaService.postSurvey(this.idMaterialita, ambiti, settori, sottoSettori, titolo)
          .pipe(
            switchMap((result) => {
              return this.materialitaService.getSurvey(this.idMaterialita!);
            }),
            map((result) => {


              this.cfgQuestionario = result;

              this.salvato.emit();

              return true;

            }),
            catchError((err) => {
              console.error('Errore durante il salvataggio', err);

              this.utilityService.openDialog({
                titolo: 'Attenzione',
                descrizione: 'Errore nel salvataggio' + (err?.error?.message ? ' : ' + err.error.message : ''),
                bottoni: [{ nome_btn: 'Ok' }]
              });

              return of(false);

            })
          );

      }
    } else {
      this.utilityService.openDialog({
        titolo: 'Attenzione',
        descrizione: 'Nessuna materialità specificata.',
        bottoni: [{ nome_btn: 'Ok' }]
      });

      return of(false);
    }

  }


  /**
   * Metodo salvataggio Questionario Sintesi
   * @returns 
   */
  private _salvaSintesiSurvey(): Observable<boolean> {

    const ambiti = this._gestioneAmbitiPreSalvataggio();

    const id = this.formCreaCfgQuestionario.get('id')?.value || [];
    const settori = this.formCreaCfgQuestionario.get('settori')?.value ? [(this.formCreaCfgQuestionario.get('settori')!.value as Settore)] : [];
    const sottoSettori = this.formCreaCfgQuestionario.get('sottoSettori')?.value || [];
    const titolo = this.formCreaCfgQuestionario.get('titolo')?.value || '';
    /*  const tipoPunteggio = this.formCreaCfgQuestionario.get('tipoPunteggio')?.value || '';
     const scadenzaSurvey = this.formCreaCfgQuestionario.get('scadenzaSurvey')?.value || ''; */


    // Aggiungo il tipo "scelta_multipla_valore" sulla tematica se sono in sintesi 
    this.addTipoSuTematica();

    if (this.idSostenibilita) {



      return this.sintesiService.putSintesiSurveyStep2(
        this.idSostenibilita,
        this.cfgQuestionario.id,
        ambiti,
        settori,
        sottoSettori,
        titolo,
        this.cfgQuestionario.stato,
        this.cfgQuestionario.tipo).pipe(
          map((survey) => {

            this.cfgQuestionario = survey;

            this.salvato.emit();

            return true;

          }),
          catchError((err) => {
            console.error('Errore durante il salvataggio', err);

            this.utilityService.openDialog({
              titolo: 'Attenzione',
              descrizione: 'Errore nel salvataggio' + (err?.error?.message ? ' : ' + err.error.message : ''),
              bottoni: [{ nome_btn: 'Ok' }]
            });

            return of(false);

          })
        );

    } else {
      this.utilityService.openDialog({
        titolo: 'Attenzione',
        descrizione: 'Nessuna Sintesi specificata.',
        bottoni: [{ nome_btn: 'Ok' }]
      });

      return of(false);
    }

  }


  /**
   * Metodo salvataggio Questionario Bilancio
   * @returns 
   */
  private _salvaBilancioSurvey(): Observable<boolean> {


    const ambiti = this._gestioneAmbitiPreSalvataggio();


    const id = this.formCreaCfgQuestionario.get('id')?.value || [];
    const settori = this.formCreaCfgQuestionario.get('settori')?.value ? [(this.formCreaCfgQuestionario.get('settori')!.value as Settore)] : [];
    const titolo = this.formCreaCfgQuestionario.get('titolo')?.value || '';


    // Aggiungo il tipo "scelta_multipla_valore" sulla tematica se sono in bilancio 
    this.addTipoSuTematica();


    if (this.idSostenibilita) {



      return this.bilancioService.putQuestionarioBilancio(this.idSostenibilita, this.cfgQuestionario.id, ambiti, settori, titolo, this.cfgQuestionario.stato, this.cfgQuestionario.tipo).pipe(
        map((survey) => {

          this.cfgQuestionario = survey;

          this.salvato.emit();

          return true;

        }),
        catchError((err) => {
          console.error('Errore durante il salvataggio', err);

          this.utilityService.openDialog({
            titolo: 'Attenzione',
            descrizione: 'Errore nel salvataggio' + (err?.error?.message ? ' : ' + err.error.message : ''),
            bottoni: [{ nome_btn: 'Ok' }]
          });

          return of(false);

        })
      );

    } else {
      this.utilityService.openDialog({
        titolo: 'Attenzione',
        descrizione: 'Nessuna Sintesi specificata.',
        bottoni: [{ nome_btn: 'Ok' }]
      });

      return of(false);
    }

  }

  /**
   * Metodo salvataggio Questionario Policy
   * @returns 
   */
  private _salvaPolicySurvey(): Observable<boolean> {

    const ambiti = this._gestioneAmbitiPreSalvataggio();
    const settori = this.formCreaCfgQuestionario.get('settori')?.value ? [(this.formCreaCfgQuestionario.get('settori')!.value as Settore)] : [];
    const titolo = this.formCreaCfgQuestionario.get('titolo')?.value || '';

    // Aggiungo il tipo "scelta_multipla_valore" sulla tematica se sono in policy 
    this.addTipoSuTematica();

    if (this.idSostenibilita) {

      return this.policyService.putQuestionarioPolicy(this.idSostenibilita, this.cfgQuestionario.id, ambiti, settori, titolo, this.cfgQuestionario.stato, this.cfgQuestionario.tipo).pipe(
        map((survey) => {

          this.cfgQuestionario = survey;

          this.salvato.emit();

          return true;

        }),
        catchError((err) => {
          this.utilityService.openDialog({
            titolo: 'Attenzione',
            descrizione: 'Errore nel salvataggio' + (err?.error?.message ? ' : ' + err.error.message : ''),
            bottoni: [{ nome_btn: 'Ok' }]
          });

          return of(false);

        })
      );

    } else {
      this.utilityService.openDialog({
        titolo: 'Attenzione',
        descrizione: 'Nessuna Policy specificata.',
        bottoni: [{ nome_btn: 'Ok' }]
      });

      return of(false);
    }
  }
  /**
   * Metodo salvataggio Questionario Piano
   * @returns 
   */
  private _salvaPianoSurvey(): Observable<boolean> {

    const ambiti = this._gestioneAmbitiPreSalvataggio();
    const settori = this.formCreaCfgQuestionario.get('settori')?.value ? [(this.formCreaCfgQuestionario.get('settori')!.value as Settore)] : [];
    const titolo = this.formCreaCfgQuestionario.get('titolo')?.value || '';

    // Aggiungo il tipo "scelta_multipla_valore" sulla tematica se sono in piano 
    this.addTipoSuTematica();

    if (this.idSostenibilita) {

      return this.pianoService.putQuestionarioPiano(this.idSostenibilita, this.cfgQuestionario.id, ambiti, settori, titolo, this.cfgQuestionario.stato, this.cfgQuestionario.tipo).pipe(
        map((survey) => {

          this.cfgQuestionario = survey;

          this.salvato.emit();

          return true;

        }),
        catchError((err) => {
          this.utilityService.openDialog({
            titolo: 'Attenzione',
            descrizione: 'Errore nel salvataggio' + (err?.error?.message ? ' : ' + err.error.message : ''),
            bottoni: [{ nome_btn: 'Ok' }]
          });

          return of(false);

        })
      );

    } else {
      this.utilityService.openDialog({
        titolo: 'Attenzione',
        descrizione: 'Nessuna piano specificata.',
        bottoni: [{ nome_btn: 'Ok' }]
      });

      return of(false);
    }
  }

  private _gestioneAmbitiPreSalvataggio() {
    // Si ricostruiscono gli ambiti ricavando i dati dalla survey originale,
    // così da preservare eventuali info presenti in precedenza (caso di edit)

    const ambitiSelezionati = this.formCreaCfgQuestionario.get('ambiti')?.value || [];
    const ambitiSurvey = (this._cfgQuestionario?.ambiti || []) as Ambito[];
    const ambiti =
      ambitiSurvey // prende gli ambiti esistenti nella survey
        .filter((ambitoPre) => { // Filtra per includere solo quelli selezionati dall'interfaccia 
          return ambitiSelezionati.findIndex(ambitoSel => ambitoSel.id === ambitoPre.id) >= 0;
        });

    ambiti.push( // pusha tutti gli ambiti non esistenti nella survey
      ...(ambitiSelezionati.filter((ambitoSel) => ambitiSurvey.findIndex(ambitoSurvey => ambitoSurvey.id === ambitoSel.id) < 0))
    );

    // per ogni ambito, si allineano le tematiche selezionate
    ambiti?.forEach((ambito: Ambito) => {

      // Delle tematiche presenti, tiene solo quelle nell'array tematiche selezionate
      ambito.tematiche = (ambito.tematiche || []).filter((tematica) => {
        if (!this.arrayTematicheSel[ambito.id]) {
          return false;
        } else {
          return this.arrayTematicheSel[ambito.id].findIndex((tematicaSel: Tematica) => tematicaSel.id === tematica.id) >= 0
        }
      });

      // Aggiunge tematiche selezionate non esistenti nell'array tematiche
      if (this.arrayTematicheSel[ambito.id]) {
        ambito.tematiche.push(
          ...(this.arrayTematicheSel[ambito.id].filter((tematicaSel: Tematica) => {
            return ambito.tematiche.findIndex((tematica) => tematica.id === tematicaSel.id) < 0
          })
          )
        );
      }

    })

    const amb = ambiti.filter(amb => amb.tematiche.length); // salvo solamente gli ambiti che hanno una tematica selezionata

    return amb;


  }

  private _getTematicheDisponibili(idAmbito: string) {


    if (!idAmbito) {
      this.utilityService.openDialog({
        titolo: 'Attenzione',
        descrizione: 'Errore nessun ambito passato',
        bottoni: [{ nome_btn: 'Ok' }]
      })
      return;
    }

    this.loadingTemDisp[idAmbito] = true;

    let filtroAree = '';
    if (this.tipoQuestionario === 'AUTOVALUTAZIONE') {
      filtroAree = 'autovalutazioni';
    } else if (this.tipoQuestionario === 'SURVEY') {
      filtroAree = 'survey';
    } else if (this.tipoQuestionario === 'SINTESI' || this.tipoQuestionario === 'BILANCIO') {
      filtroAree = 'bilanci';
    } else if (this.tipoQuestionario === 'POLICY') {
      filtroAree = 'policy';
    } else if (this.tipoQuestionario === 'PIANO') {
      filtroAree = 'piano';
    }

    let filters: Filters[] = [
      {
        chiave: 'idsAmbiti',
        operator: 'eq',
        values: [idAmbito]
      },
      {
        chiave: 'aree',
        operator: 'eq',
        values: [filtroAree]
      }
    ];

    const sottoSettori = this.formCreaCfgQuestionario.get('sottoSettori')?.value;

    if (sottoSettori?.length) {
      // Ci sono sottosettori, si filtrano le tematiche anche per quelli
      filters.push({
        chiave: 'idSottoSettori',
        operator: 'eqOrEmpty',
        values: sottoSettori.map((sotSet: SottoSettore) => sotSet.id)
      });
    }

    this.tematicheService.getTematiche(0, 1000, '',
      filters,
      [
        {
          chiave: 'index',
          sort: 'asc',
        },
        {
          chiave: 'domanda',
          sort: 'desc'
        }]
    ).subscribe(
      {
        next: (result: any) => {


          this.arrayTematicheDisponibili[idAmbito] = result?.content || []; // TODO SORTARE CON .SORT E INFINITY 

          if (this.arrayTematicheSel[idAmbito]) {

            // Se il questionario è in bozza, rimappa le tematiche con quelle di libreria per prendere eventuali modifiche
            if (this.stato === 'BOZZA') {
              this.arrayTematicheSel[idAmbito] = this.arrayTematicheSel[idAmbito].map((val: Tematica) => {
                const tematica: Tematica = this.arrayTematicheDisponibili[idAmbito].find((valFind: Tematica) => {
                  return valFind.id === val.id
                });
                if (tematica) {
                  tematica.locked = !!val.locked;
                  tematica.infos = val.infos;
                }
                return tematica;

              }).filter((tema: Tematica) => !!tema);
            }

            this.arrayTematicheDisponibili[idAmbito] = this.arrayTematicheDisponibili[idAmbito].filter((val: Tematica) => {
              return !this.arrayTematicheSel[idAmbito].find((valFind: Tematica) => {
                return valFind.id === val.id;
              });
            });

            setTimeout(() => {
              const scrollbarSelezionate = this.scrollSelezionate.find((item) =>
                item.nativeElement.id === idAmbito + '_sel'
              )
              if (scrollbarSelezionate) {
                scrollbarSelezionate.update();
              }
              const scrollbarDisponibili = this.scrollDispobibili.find((item) =>
                item.nativeElement.id === idAmbito + '_disp'
              )
              if (scrollbarDisponibili) {
                scrollbarDisponibili.update();
              }

            }, 0);
          }

          this.loadingTemDisp[idAmbito] = false;
        },
        error: (err: any) => {
          console.error('Errore :', err);

          this.loadingTemDisp[idAmbito] = false;

          this.utilityService.openDialog({
            titolo: 'Attenzione',
            descrizione: 'Errore nel recupero delle tematiche',
            bottoni: [{ nome_btn: 'Ok' }]
          })
        }
      }
    )
  }

  /**
   * tooltip da mostare nella icona info su tematiche selezionate / disponibili
   * @param infos info list 
   * @returns 
   */
  tooltipInfos(infos: any) {
    let tooltipInfo: string = '';
    for (let i = 0; i < infos.length; i++) {
      if (i > 0) {
        tooltipInfo += '; ';
      }
      tooltipInfo += infos[i];
    }
    return tooltipInfo;
  }



}
