import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Filters, SortBy } from 'src/app/components/lista-tabellare/classes/lista-tabellare-data-source';
import { WebService } from '../web/web.service';
import { Ambito } from '../ambiti/ambiti.service';
import { Settore, SottoSettore } from '../settori/settori.service';
import { AziendaService } from '../azienda/azienda.service';

@Injectable({
  providedIn: 'root'
})
export class AutovalutazioniWebService {

  private lang = '/it-IT';


  private _idAzienda: string | undefined = undefined;

  constructor(
    private aziendaService: AziendaService,
    private webService: WebService
  ) {
    this.aziendaService.aziendaCorrente.subscribe((azienda) => {
      this._idAzienda = azienda?.id;
    })
  }

  public getCfgAutovalutazioni(page: number, pageSize: number, ricerca?: string, filters?: Filters[], sortBy?: SortBy[]): Observable<any> {
    return this.webService.doPost('/api/v1/lang' + this.lang + '/autovalutazioni/list', {
      ricerca,
      filters,
      page,
      pageSize,
      sortBy
    });
  }

  public duplicaAutovalutazione(id: string): Observable<any> {
    return this.webService.doPost('/api/v1/lang' + this.lang + '/autovalutazioni/duplica', {}, {
      id
    });
  }

  public getAutovalutazioni(page: number, pageSize: number, ricerca?: string, filters?: Filters[], sortBy?: SortBy[]): Observable<any> {
    return this.webService.doPost('/api/v1/lang' + this.lang + '/azienda/' + this._idAzienda + '/autovalutazioni-compilate/list', {
      ricerca,
      filters,
      page,
      pageSize,
      sortBy
    });
  }

  public putPubblicaAutovalutazione(id: string): Observable<any> {
    return this.webService.doPut('/api/v1/lang' + this.lang + '/autovalutazioni/pubblica', undefined, {
      id
    });
  }

  public delCfgAutovalutazione(id: string): Observable<any> {
    return this.webService.doDelete('/api/v1/lang' + this.lang + '/autovalutazioni/delete', {
      id
    });
  }

  public postCfgAutovalutazione(titolo: string, ambiti: Ambito[], tipo: string, tipoAutovalutazione: 'GENERICO' | 'SETTORE', settori: Settore[], sottoSettori: SottoSettore[]): Observable<any> {
    return this.webService.doPost('/api/v1/lang' + this.lang + '/autovalutazioni', {
      titolo,
      ambiti,
      tipo,
      tipoAutovalutazione,
      settori,
      sottoSettori,
      codiceLingua: 'it-IT',
      aziende: [this._idAzienda]
    });
  }

  public getCfgAutovalutazioneById(id: string): Observable<any> {
    return this.webService.doGet('/api/v1/lang' + this.lang + '/autovalutazioni/get', { id });
  }

  public putCfgAutovalutazione(id: string, titolo: string, chiave: string, ambiti: Ambito[], tipo: string, tipoAutovalutazione: 'GENERICO' | 'SETTORE', settori: Settore[], sottoSettori: SottoSettore[], stato: string): Observable<any> {
    return this.webService.doPut('/api/v1/lang' + this.lang + '/autovalutazioni/aggiorna', {
      id,
      titolo,
      chiave,
      ambiti,
      tipo,
      tipoAutovalutazione,
      settori,
      sottoSettori,
      codiceLingua: 'it-IT',
      stato,
      aziende: [this._idAzienda]
    }, { id });
  }


  /****************************************************** COMPILATE E NON */

  public getAutovalutazioniAzienda(page: number, pageSize: number, ricerca?: string, filters?: Filters[], sortBy?: SortBy[]): Observable<any> {
    return this.webService.doPost('/api/v1/lang' + this.lang + '/azienda/' + this._idAzienda + '/autovalutazioni-compilate/list', {
      ricerca,
      filters,
      page,
      pageSize,
      sortBy
    });
  }

  public getAutovalutazioniById(id: string) {
    return this.webService.doGet('/api/v1/lang' + this.lang + '/azienda/' + this._idAzienda + '/autovalutazioni-compilate/id', {
      id
    });
  }

  public salvaCompilazione(idAutovalutazioneCompilata: string, autovalutazioneCompilata: any): Observable<any> {
    return this.webService.doPut('/api/v1/lang' + this.lang + '/azienda/' + this._idAzienda + '/autovalutazioni-compilate/' + idAutovalutazioneCompilata, autovalutazioneCompilata);
  }


  /******************************************************** CODICE ATECO */

  public getAutovalutazioneCodiceAteco(codAteco: string, page: number, pageSize: number, ricerca?: string, filters?: Filters[], sortBy?: SortBy[]): Observable<any> {

    return this.webService.doPost('/api/v1/lang' + this.lang + '/autovalutazioni/list/ateco/' + codAteco, {
      ricerca,
      filters,
      page,
      pageSize,
      sortBy
    });
  }

  public creaAutovalutazioneCompilate(annoDiRiferimento: number, idAutovalutazione: string, idFigura: string): Observable<any> {
    return this.webService.doPost('/api/v1/lang' + this.lang + '/azienda/' + this._idAzienda + '/autovalutazioni-compilate/anno-di-riferimento/'
      + annoDiRiferimento + '/autovalutazione/' + idAutovalutazione + '/figura/' + idFigura, {
    });
  }

  public getAutovalutazioneCompilate(anno?: string, idSettore?: string, idSottoSettori?: string[]): Observable<any> {
    return this.webService.doPost('/api/v1/lang' + this.lang + '/azienda/' + this._idAzienda + '/autovalutazioni-compilate/settore/' + idSettore + '/anno/' + anno + '/list', {
      idSottoSettori
    });
  }

  public stampaAutovalutazione(idAutovalutazioneCompilata: string): Observable<any> {
    return this.webService.doPost('/api/v1/lang' + this.lang + '/azienda/' + this._idAzienda + '/autovalutazioni-compilate/autovalutazione/' + idAutovalutazioneCompilata + '/stampa', {});
  }


  public getAutovalutazioneEsternaDaCompilare(temporaryToken: string) {
    return this.webService.doGet('/api/v1/compila-autovalutazione/get', {
      temporaryToken
    });
  }

  public postAutovalutazioneEsterniDaCompilare(temporaryToken: string, objEsterna: any) {
    return this.webService.doPost('/api/v1/compila-autovalutazione/compila',
      objEsterna,
      {
        temporaryToken
      });
  }

  public inviaNotifica(idAutovalutazioneCompilata: string) {
    return this.webService.doPost('/api/v1/lang' + this.lang + '/azienda/' + this._idAzienda + '/autovalutazioni-compilate/autovalutazione/' + idAutovalutazioneCompilata + '/notifica', {});
  }

  public eliminaAutovalutazione(idAutovalutazione: string) {
    return this.webService.doDelete('/api/v1/lang' + this.lang + '/azienda/' + this._idAzienda + '/autovalutazioni-compilate/delete/' + idAutovalutazione, {})
  }

}
