import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SpinnerOverlayComponent } from 'src/app/components/spinner-overlay/spinner-overlay.component';
import { Azienda, AziendaService } from 'src/app/services/azienda/azienda.service';
import { Responsabile, RuoliList, Utente, UtenteService } from 'src/app/services/utente/utente.service';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { DialogGestioneLicenzeComponent } from '../dialog-gestione-licenze/dialog-gestione-licenze.component';
import { Observable, finalize, firstValueFrom, of } from 'rxjs';
import { MatSelectChange } from '@angular/material/select';
import { Filters } from 'src/app/components/lista-tabellare/classes/lista-tabellare-data-source';

@Component({
  selector: 'app-dialog-crea-utenti',
  templateUrl: './dialog-crea-utenti.component.html',
  styleUrls: ['./dialog-crea-utenti.component.scss'],
})
export class DialogCreaUtentiComponent implements OnInit, AfterViewInit {
  @ViewChild(SpinnerOverlayComponent) spinnerOver!: SpinnerOverlayComponent;
  public arrayRuoli: RuoliList[] = [];
  public objResponsabile: Responsabile[] = [];
  public arrayAziende: Azienda[] = [];
  public selectedAzienda: string[] = [];
  public isPermessoSoloRead: boolean = (!this.utenteService.isPermessoAttivo('EDIT_AZIENDA') && !this.utenteService.isPermessoAttivo('CREATE_AZIENDA'))
  private ruoloScelto: string = '';
  public loadingResponsabile: boolean = false;
  /* Form Controls */
  public formUtenti = new FormGroup({

    /* Nome */
    firstName: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required, UtilityService.blankValidator],
    }),
    /* Cognome */
    lastName: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required, UtilityService.blankValidator],
    }),
    /* email */
    email: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required, Validators.email, UtilityService.emailValidator],
    }),

    /* Ruoli Utente */
    ruolo: new FormControl<RuoliList | undefined>(undefined, {
      nonNullable: true,
      validators: [Validators.required],
    }),

    /* Responsabile */
    utenteResponsabile: new FormControl<Responsabile | undefined>(undefined, {
      nonNullable: true,
      validators: [],
    }),

    /* aziende */
    idAziende: new FormControl<string[]>([]),

    /* username  */
    username: new FormControl<string>(''),
    /* attivo */
    attivo: new FormControl<boolean>(true),
    /* keyId */
    keyId: new FormControl<string | undefined>(undefined),
    /* CMSId */
    CMSId: new FormControl<string | undefined>(undefined),
    /* id */
    id: new FormControl<string | undefined>(undefined),

    /* Ruoli Utente */
    codiceLicenza: new FormControl<string | undefined>(undefined, {
      nonNullable: true,
      validators: [],
    }),
  });


  constructor(
    public dialogRef: MatDialogRef<DialogCreaUtentiComponent>,
    public utenteService: UtenteService,
    public aziendaService: AziendaService,
    private readonly utilityService: UtilityService,
    private readonly dialog: MatDialog,
    private cdRef: ChangeDetectorRef,

    @Inject(MAT_DIALOG_DATA) public data: {
      utente: Utente,
      idAzienda: string
    }
  ) {
  }

  ngOnInit(): void {

  }

ngAfterViewInit(): void {

  const ruoloUtenteLogin = this.utenteService.infoUtente?.utente?.ruolo?.roleName;

  if (ruoloUtenteLogin && ['collaboratore', 'collaboratore_go'].includes(ruoloUtenteLogin)) {
    this.formUtenti.get('idAziende')?.addValidators(Validators.required);
    this.formUtenti.get('utenteResponsabile')?.addValidators(Validators.required);
    this.formUtenti.get('idAziende')?.updateValueAndValidity();
    this.formUtenti.get('utenteResponsabile')?.updateValueAndValidity();
  }

  this.ctrDisableCampi();
  this._getRuoli();
  this._getUtentiPerCollaboratore(this.data.utente?.ruolo?.roleName);
  this.datiIniziali();

  this.cdRef.detectChanges(); // Forza il rilevamento delle modifiche
}
  /**
   * Nel cambio di un ruolo utente
   * @param event 
   */
  onSelectionChangeRuolo(event: MatSelectChange) {
    this.ruoloScelto = event.value.roleName;
    const form = this.formUtenti;
    const isRuoloSceltoCollaboratore = this.ruoloScelto === 'collaboratore' || this.ruoloScelto === 'collaboratore_go';
    const isReadOnly = this.ruoloScelto === 'utente_read_only' || this.ruoloScelto === 'utente_read_only_go';
    const utenteLogin = this.utenteService.infoUtente?.utente
    const isCurrentUserConfiguratore = utenteLogin?.ruolo?.roleName === 'configuratore';

    if (this.ruoloScelto === 'configuratore') {
      this._getAziendeView([{
        values: [this.ruoloScelto],
        operator: 'eq',
        chiave: 'ruolo'
      },]);
    }
    // Se il ruolo selezionato è 'collaboratore' e l'utente corrente è un 'configuratore'
    else if (isRuoloSceltoCollaboratore && isCurrentUserConfiguratore) {
      // Resetta i campi 'utenteResponsabile' e 'idAziende'
      form.get('utenteResponsabile')?.setValue(undefined);
      form.get('utenteResponsabile')?.addValidators(Validators.required);
      form.get('idAziende')?.setValue([]);

      // Recupero responsabili
      this._getUtentiPerCollaboratore(this.ruoloScelto);

    }
    else {
      // Rimuove i validatori se non applicabile
      form.get('utenteResponsabile')?.clearValidators();
      if (!isRuoloSceltoCollaboratore) {
        this._getAziendeGoOrCloud(this.ruoloScelto);
      }
    }

    // Aggiunge o rimuove i validatori in base al ruolo
    if (isRuoloSceltoCollaboratore || isReadOnly) {
      form.get('idAziende')?.addValidators(Validators.required);

      let filtroAziende: Filters[] = [{
        values: utenteLogin?.idAziende || [],
        operator: 'eq',
        chiave: 'id'
      },
      {
        values: [this._getTipoInstallazione(event.value?.roleName)],
        operator: 'eq',
        chiave: 'tipoInstallazione'
      },
      {
        values: [this.ruoloScelto],
        operator: 'eq',
        chiave: 'ruolo'
      },
      ]
      this._getAziendeView(filtroAziende)
    } else {
      form.get('idAziende')?.clearValidators();
    }

    // Aggiorna la validità dei campi
    form.get('utenteResponsabile')?.updateValueAndValidity();
    form.get('idAziende')?.updateValueAndValidity();
  }

  /**
   * Nel cambio del responsabile se selezionato come ruolo Collaboratore
   * @param event 
   */
  onSelectionChangeResponsabile(event: MatSelectChange) {
    console.log(event.value);

    let filtroAziende: Filters[] = [{
      values: event.value.idAziende,
      operator: 'eq',
      chiave: 'id'
    },
    {
      values: [this._getTipoInstallazione(event.value.ruolo.roleName)],
      operator: 'eq',
      chiave: 'tipoInstallazione'
    },
    {
      values: [this.ruoloScelto],
      operator: 'eq',
      chiave: 'ruolo'
    },
    ]
    this._getAziendeView(filtroAziende)
  }

  /**
  * Funzione di salvataggio di un nuovo Utente
  * @returns 
  */
  salva() {

    if (this.formUtenti.valid) {
      const keyId = this.formUtenti.get('keyId')?.value;

      const ruoloSelezionato = this.formUtenti.get('ruolo')?.value?.roleName;
      if (ruoloSelezionato !== 'collaboratore' && ruoloSelezionato !== 'collaboratore_go') {
        this.formUtenti.get('utenteResponsabile')?.setValue(undefined);
      }

      this.spinnerOver.show();
      if (keyId) {

        return this.utenteService.putUtente(keyId,
          this.formUtenti.getRawValue()).pipe(
            finalize(() => this.spinnerOver.hide())
          )
          .subscribe({
            next: () => {
              this.utilityService.opneSnackBar('Aggiornamento effettuato', '', {
                duration: 2000,
                panelClass: ['success-snackbar']
              });
              this.dialogRef.close();
            },
            error: (err: any) => {
              console.error('Errore durante l\'aggiornamento dell\'Utente: ', err);

              this.utilityService.openDialog({
                titolo: 'Attenzione',
                descrizione: err?.error?.message || 'Errore durante l\'aggiornamento dell\'Utente',
                bottoni: [{ nome_btn: 'Chiudi' }]
              },
                500);
            }
          })


      } else {
        return this.utenteService.postUtente(this.formUtenti.getRawValue()).subscribe({
          next: (result: any) => {
            this.utilityService.opneSnackBar('Salvataggio effettuato ', '', {
              duration: 2000,
              panelClass: ['success-snackbar']
            });
            this.dialogRef.close();
            this.spinnerOver.hide();
          },
          error: (err: any) => {
            console.error('Errore durante il salvataggio dell\'Utente:', err);
            this.utilityService.openDialog({
              titolo: 'Attenzione',
              descrizione: err?.error?.message || 'Errore nel salvataggio dell\'Utente',
              bottoni: [{ nome_btn: 'Chiudi' }]
            },
              500);
            this.spinnerOver.hide();
          }
        })
      }

    } else {
      Object.values(this.formUtenti.controls).forEach(
        (control) => {
          control.markAsTouched();
        }
      );
    }
    return null;
  }

  compareRuoli(ruolo1: RuoliList, ruolo2: RuoliList): boolean {
    return ruolo1?.roleName === ruolo2?.roleName; // Sostituisci "id" con la proprietà univoca degli ambiti
  }

  compareResponsabile(resp1: Responsabile, resp2: Responsabile): boolean {
    return resp1?.keyId === resp2?.keyId;
  }

  private _getRuoli() {

    let obsRuoli: any;

    if (this.data?.utente?.id) {
      obsRuoli = this.utenteService.getRuoli();
    } else {
      obsRuoli = this.utenteService.getRuoliCreate()
    }

    this.spinnerOver.show();
    obsRuoli.pipe(
      finalize(() => this.spinnerOver.hide())
    )
      .subscribe(
        {
          next: (result: any) => {

            this.arrayRuoli = result || [];

          },
          error: (err: any) => {
            console.error('Errore durante il caricamento dei Ruoli:', err);

            this.utilityService.openDialog({
              titolo: 'Attenzione',
              descrizione: 'Errore nel recupero dei Ruoli',
              bottoni: [{ nome_btn: 'Ok' }]
            })
          }
        }
      )
  }

  private _getAziendeView(filtroAzienda?: Filters[]) {

    if (!filtroAzienda) {
      filtroAzienda = [];
    }

    this.spinnerOver.show();
    this.aziendaService.getAziendeView(0, 1000, '', filtroAzienda, [{
      chiave: 'ragioneSociale',
      sort: 'asc'
    }])
      .pipe(
        finalize(() => this.spinnerOver.hide())
      )
      .subscribe({
        next: (result: any) => {

          this.arrayAziende = result?.content || [];

          this.formUtenti.get('idAziende')?.setValue(
            (this.formUtenti.get('idAziende')?.value || []).filter((val) => {
              return !!this.arrayAziende.find(ditta => ditta.id === val);
            })
          );

        },
        error: (err: any) => {
          console.error('Errore durante il caricamento delle aziende:', err);

          this.utilityService.openDialog({
            titolo: 'Attenzione',
            descrizione: 'Errore nel recupero delle aziende',
            bottoni: [{ nome_btn: 'Ok' }]
          })
        }
      })
  }

  public datiIniziali() {

    if (this.data?.utente) {
      Object.keys(this.data.utente).forEach((value, index, array) => {

        if (value === 'utenteResponsabile') {
          this.formUtenti.get('utenteResponsabile')?.setValue(this.data.utente.utenteResponsabile);
        }

        this.formUtenti.get(value)?.setValue((this.data.utente as any)[value]);
      })
      this.formUtenti.controls.email.disable();
    }

    if (this.data?.utente?.ruolo?.roleName) {
      this._getUtentiPerCollaboratore(this.data?.utente?.ruolo?.roleName);
    }

    if (this.data.idAzienda) {
      let filtroAziende: Filters[] = [{
        values: [this.data.idAzienda],
        operator: 'eq',
        chiave: 'id'
      },
      {
        values: [this._getTipoInstallazione(this.data.utente.ruolo.roleName)],
        operator: 'eq',
        chiave: 'tipoInstallazione'
      }]
      this._getAziendeView(filtroAziende)

      this.formUtenti.controls.idAziende.disable();
    } else {
      let filtroAziende: Filters[];

      if (this.data?.utente?.utenteResponsabile?.idAziende) {
        // Prendo le aziende dal responsabile dell'utente
        filtroAziende = [{
          values: this.data.utente.utenteResponsabile.idAziende,
          operator: 'eq',
          chiave: 'id'
        },
        {
          values: [this.data.utente.ruolo.roleName],
          operator: 'eq',
          chiave: 'ruolo'
        },
        {
          values: [this._getTipoInstallazione(this.data.utente.ruolo.roleName)],
          operator: 'eq',
          chiave: 'tipoInstallazione'
        },
        ];
        this._getAziendeView(filtroAziende);
      } else if (this.data?.utente?.idAziende) {
        // Prendo le aziende dall'utente
        let filtroAziende: Filters[] = [{
          values: this.data.utente.idAziende,
          operator: 'eq',
          chiave: 'id'
        },
        {
          values: [this._getTipoInstallazione(this.data.utente.ruolo.roleName)],
          operator: 'eq',
          chiave: 'tipoInstallazione'
        }]
        this._getAziendeView(filtroAziende);
      } else {
        // Prendo le aziende in cloud o GO
        this._getAziendeGoOrCloud(this.data?.utente?.ruolo?.roleName);
      }
    }
    const ruolo = this.formUtenti.get('ruolo')?.value;
    const isAziendaObbligatoria = ruolo && ['collaboratore', 'collaboratore_go', 'utente_read_only', 'utente_read_only_go'].includes(ruolo.roleName);

    if (isAziendaObbligatoria) {
      this.formUtenti.get('idAziende')?.addValidators(Validators.required);
      this.formUtenti.get('idAziende')?.updateValueAndValidity();
    }

  }

  private _getAziendeByIdAzienda() {

    this.aziendaService.getAziendeByIdAzienda([this.data.idAzienda], 0, 1000, '', [], [{
      chiave: 'ragioneSociale',
      sort: 'asc'
    }]).subscribe({
      next: (result: any) => {

        this.arrayAziende = result?.content || [];
        Object.values(this.arrayAziende).forEach(azienda => {
          this.selectedAzienda = [azienda.id];
        });;
      },
      error: (err: any) => {
        console.error('Errore durante il caricamento delle aziende:', err);

        this.utilityService.openDialog({
          titolo: 'Attenzione',
          descrizione: 'Errore nel recupero delle aziende',
          bottoni: [{ nome_btn: 'Ok' }]
        })
      }
    })
  }

  salvaDentro(): Observable<boolean> {

    if (this.formUtenti.valid) {
      this.spinnerOver.show();
      return new Observable(obs => {
        this.utenteService.postUtente(this.formUtenti.getRawValue()).subscribe({
          next: (result: any) => {
            this.utilityService.opneSnackBar('Salvataggio effettuato ', '', {
              duration: 2000,
              panelClass: ['success-snackbar']
            });
            this.formUtenti.get('id')?.setValue(result?.id)
            this.formUtenti.get('keyId')?.setValue(result?.keyId)
            this.formUtenti.get('codiceLicenza')?.setValue(result?.codiceLicenza);
            this.spinnerOver.hide();
            obs.next(result);
            obs.complete();
            return of(true);
          },
          error: (err: any) => {
            console.error('Errore durante il salvataggio dell\'Utente:', err);
            this.utilityService.openDialog({
              titolo: 'Attenzione',
              descrizione: err?.error?.message || 'Errore nel salvataggio dell\'Utente',
              bottoni: [{ nome_btn: 'Chiudi' }]
            },
              500);
            this.spinnerOver.hide();
            obs.error(err);
            return of(false);
          }
        });
      });
    } else {
      Object.values(this.formUtenti.controls).forEach(
        (control) => {
          control.markAsTouched();
        }
      );
      return of(false);
    }
  }

  /**
   * Metodo che mi apre la dialog di Gestione Licenze per utente 
   */
  public async gestioneLicenze() {

    let resultSalvataggio: boolean = false;
    if (!this.formUtenti.get('keyId')?.value) {
      try {
        resultSalvataggio = await firstValueFrom(this.salvaDentro());
      } catch (error) {
        console.error('Errore durante l\'esecuzione del salvataggio:', error);
      }
      if (resultSalvataggio) {
        this.openDialogLicenze();
      }
    } else {
      this.openDialogLicenze();
    }
  }

  openDialogLicenze() {
    const gestioneLicenzeDialog = this.dialog.open(DialogGestioneLicenzeComponent, {
      data: {
        idUtente: this.formUtenti.get('id')?.value,
        ruoloUtente: this.formUtenti.get('ruolo')?.value,
        codiceLicenza: this.formUtenti.get('codiceLicenza')?.value,
      },
      panelClass: 'dialog-container',
      disableClose: false,
      width: '95%',
      maxHeight: '95%',
      autoFocus: false,
    });

    gestioneLicenzeDialog.afterClosed().subscribe((result) => {

      if (result?.codiceLicenza) {
        this.formUtenti.get('codiceLicenza')?.setValue(result?.codiceLicenza)
      }
    });
  }

  public canSeeLicenze() {
    const ruoloSelezionato = this.formUtenti.get('ruolo')?.value?.roleName;

    if (this.utenteService.isPermessoAttivo('ABILITA_LICENZA') && ((ruoloSelezionato !== 'collaboratore' && ruoloSelezionato !== 'utente_read_only') && !ruoloSelezionato?.includes('_go'))) {
      return true
    }
    return false
  }

  /**
 * Metodo che mi filtra i responsabili quando selezionato ruolo collaboratore
 * Se sono un utente sotto configuratore allora il responsabile mi viene assegnato in automatico. 
 */
  private _getUtentiPerCollaboratore(ruolo?: string) {

    let filtroUtenti: Filters[] = [{
      values: [true],
      operator: 'is',
      chiave: 'attivo'
    }];

    if (ruolo && this._getTipoInstallazione(ruolo) === 'SOSTENIBILE_GO') {
      filtroUtenti.push({
        values: ['utente_premium_go'],
        operator: 'eq',
        chiave: 'roleName'
      })
    } else {
      filtroUtenti.push({
        values: ['utente_premium', 'professionista'],
        operator: 'eq',
        chiave: 'roleName'
      })
    }
    this.loadingResponsabile = true;
    this.formUtenti.get('utenteResponsabile')?.disable();

    this.spinnerOver.show();
    this.utenteService.getUtenti(0, 1000, '', filtroUtenti).pipe(
      finalize(() => {
        this.loadingResponsabile = false;
        this.formUtenti.get('utenteResponsabile')?.enable();
      })
    )
      .subscribe({
        next: (esito => {
          this.objResponsabile = [];
          esito.content.forEach((utente: Utente) => {
            const Responsabile = {
              keyId: utente?.keyId,
              id: utente?.id,
              nomeUtente: utente.firstName + ' ' + utente.lastName,
              ruolo: utente.ruolo,
              idAziende: utente.idAziende,
            }
            this.objResponsabile.push(Responsabile);
          });

        }),
        error: (err => {
          console.error(err);
        })
      })

  }

  ctrDisable(ruolo: string): boolean {
    const ruoloUtenteLogin = this.utenteService.infoUtente?.utente?.ruolo?.roleName;
    const ruoloUtenteInModifica = this.data?.utente?.ruolo?.roleName;
    const isCollaboratore = (r: string) => r === 'collaboratore' || r === 'collaboratore_go';
    const isModifica = this.formUtenti.get('id')?.value;

    // Disabilita il ruolo collaboratore se sono in modifica di un utente. Se l'utente in modifica
    // è un collaboratore allora disabilito tutti i ruoli 
    if (isModifica && (isCollaboratore(ruoloUtenteInModifica) || isCollaboratore(ruolo))) {
      return true;
    }

    // Non posso creare un utente con il mio stesso ruolo, tranne se sono un configuratore 
    if (ruolo === ruoloUtenteLogin && ruoloUtenteLogin !== 'configuratore') {
      return true;
    }

    return false;
  }

  private _getAziendeGoOrCloud(ruolo: string) {

    if (ruolo === 'configuratore') {
      this._getAziendeView();
    } else {
      //Filtro le aziende per tipo ruolo go o cloud
      let tipoInstallazione = this._getTipoInstallazione(ruolo)

      let filtroAziende: Filters[] = [{
        values: [tipoInstallazione],
        operator: 'eq',
        chiave: 'tipoInstallazione'
      }]

      if (ruolo === 'utente_read_only_go' || ruolo === 'utente_read_only') {

        filtroAziende.push({
          values: [ruolo],
          operator: 'eq',
          chiave: 'ruolo'
        })
      }

      this._getAziendeView(filtroAziende);
    }
  }

  private _getTipoInstallazione(ruolo: string) {

    if (ruolo?.includes('_go')) {
      return 'SOSTENIBILE_GO'
    }
    return 'SOSTENIBILE_CLOUD'

  }

  ctrDisableCampi() {

    if (this.data?.utente) {

      const ruoloUtenteLogin = this.utenteService.infoUtente?.utente?.ruolo?.roleName
      const keyIdUtenteLogin = this.utenteService.infoUtente?.utente?.keyId
      const idUtenteEdit = this.data?.utente?.id;
      const keyIdUtenteEdit = this.data?.utente?.keyId;
      const ruoloUtenteEdit = this.data?.utente?.ruolo?.roleName;

      if (keyIdUtenteLogin === keyIdUtenteEdit && ruoloUtenteLogin !== 'configuratore') {
        this.formUtenti.get('ruolo')?.disable()
        this.formUtenti.get('idAziende')?.disable();
        return
      }


      /*   if (keyIdUtenteLogin !== keyIdUtenteEdit && ruoloUtenteLogin && ['collaboratore', 'collaboratore_go', 'utente_read_only', 'utente_read_only_go'].includes(ruoloUtenteLogin)) {
          this.formUtenti.disable();
        } */


      if (keyIdUtenteLogin !== keyIdUtenteEdit && ruoloUtenteLogin === ruoloUtenteEdit) {
        this.formUtenti.disable();
      }




      if (this.isPermessoSoloRead) {
        this.formUtenti.disable();
      }

      if (ruoloUtenteLogin === 'collaboratore' || ruoloUtenteLogin === 'collaboratore_go') {
        if (['utente_premium', 'utente_premium_go', 'professionista'].includes(ruoloUtenteEdit)) {
          this.formUtenti.disable();
        }
      }


      if (idUtenteEdit) {
        this.formUtenti.get('ruolo')?.disable()
      }

    }
  }

}
