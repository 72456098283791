import { FnDatiOptions } from './../../../../../components/lista-tabellare/lista-tabellare.component';
import { id } from 'date-fns/locale';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { AfterViewInit, Component, ElementRef, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Observable, forkJoin, map, of, startWith, switchMap } from 'rxjs';
import { SpinnerOverlayComponent } from 'src/app/components/spinner-overlay/spinner-overlay.component';
import { KPI, KPIService, Stdr } from 'src/app/services/KPI/kpi.service';
import { AmbitiService, Ambito } from 'src/app/services/ambiti/ambiti.service';
import { AzioneBP, AzioneEBuonaPraticaService } from 'src/app/services/azione-e-buona-pratica/azione-e-buona-pratica.service';
import { Impatto, ImpattoService } from 'src/app/services/impatto/impatto.service';
import { ImpegniService, Impegno } from 'src/app/services/impegni/impegni.service';
import { AreeTematica, Tematica, TematicheService } from 'src/app/services/tematiche/tematiche.service';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { trigger, transition, style, animate } from '@angular/animations';
import { DialogFiltroGenericoComponent } from 'src/app/components/dialog-filtro-generico/dialog-filtro-generico.component';
import { Filters } from 'src/app/components/lista-tabellare/classes/lista-tabellare-data-source';
import { Certificazione, CertificazioniService } from 'src/app/services/certificazioni/certificazioni.service';
import { SDGS } from 'src/app/services/tematiche/tematiche.service';
import { Settore, SettoriService, SottoSettore } from 'src/app/services/settori/settori.service';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Obiettivo, PianoService } from 'src/app/services/piano/piano.service';

@Component({
  selector: 'app-dialog-crea-cfg-tematica',
  templateUrl: './dialog-crea-cfg-tematica.component.html',
  styleUrls: ['./dialog-crea-cfg-tematica.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('0.3s', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        animate('0.3s', style({ opacity: 0 }))
      ])
    ])
  ]
})
export class DialogCreaCfgTematicaComponent implements AfterViewInit {

  @ViewChild(SpinnerOverlayComponent) spinnerOver!: SpinnerOverlayComponent;
  @ViewChild('inputSottoSettori') inputSottoSettori!: ElementRef<HTMLInputElement>;

  public arrayAzioneBPAutoCompelte: AzioneBP[] = [];
  public arrayKpiAutoCompelte: KPI[] = [];

  public isKpi = true;
  public isAzione = true;
  public isSottoRisp = true;
  public arrayAzioneRisp: AzioneBP[] = [];
  public espandi = false;
  public arrayAmbiti: Ambito[] = [];
  public arrayAree: AreeTematica[] = [];
  public arrayObbligatorio: {
    chiave: string;
    descrizione: string;
  }[] = [];

  public arraySdgs: SDGS[] = [];

  public arraySettori: Settore[] = [];
  public settoriPerIdSottosettore: { [key: string]: Settore } = {};

  public arrayImpattiSel: Impatto[] = [];
  public arrayImpattiDisponibili: Impatto[] = [];
  public loadingImpDisp: boolean = true;

  public arrayImpegniSel: Impatto[] = [];
  public arrayImpegni: Impegno[] = [];

  public arrayKpiSel: KPI[] = [];
  public arrayKpi: KPI[] = [];

  public arrayCertificazioniSel: Certificazione[] = [];
  public arrayCertificazioni: Certificazione[] = [];

  public arrayObiettiviSel: Obiettivo[] = [];
  public arrayObiettivi: Obiettivo[] = [];


  public arrayAzioneBPsel: AzioneBP[] = [];
  public arrayAzioneBP: AzioneBP[] = [];
  public areaSelezionata: boolean = false; //nascondi e mostra tooltip
  public searchValue: string = '';

  /* Form Controls */
  public formTematiche = new FormGroup({
    /* Id */
    id: new FormControl<string | undefined>(undefined, {
      nonNullable: true,
      validators: [],
    }),
    /* chiave */
    chiave: new FormControl<string | undefined>(undefined, {
      nonNullable: true,
      validators: [],
    }),
    /* tipo */
    tipo: new FormControl<string | undefined>(undefined, {
      nonNullable: true,
      validators: [],
    }),
    /* codice */
    nomeBreve: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required, UtilityService.blankValidator],
    }),
    /* domanda */
    domanda: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required, UtilityService.blankValidator],
    }),
    /* descrizione */
    descrizione: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required, UtilityService.blankValidator],
    }),
    /* stdr */
    stdr: new FormControl<string[]>([]),

    /* SDGS */
    sdgs: new FormControl<SDGS[]>([], {
      nonNullable: true,
      validators: [],
    }),

    /* note */
    note: new FormControl<string>(''),
    idsAmbiti: new FormControl<string[]>([], {
      nonNullable: true,
      validators: [Validators.required],
    }),
    aree: new FormControl<string[]>([], {
      nonNullable: true,
      validators: [Validators.required],
    }),
    azioni: new FormControl<AzioneBP[]>([], {
      nonNullable: true,
      validators: [],
    }),
    impatti: new FormControl<Impatto[]>([], {
      nonNullable: true,
      validators: [],
    }),
    impegni: new FormControl<Impegno[]>([], {
      nonNullable: true,
      validators: [],
    }),
    kpis: new FormControl<KPI[]>([], {
      nonNullable: true,
      validators: [],
    }),
    certificazioni: new FormControl<Certificazione[]>([], {
      nonNullable: true,
      validators: [],
    }),
    obiettivi: new FormControl<Obiettivo[]>([], {
      nonNullable: true,
      validators: [],
    }),

    opzioni: new FormArray<FormGroup<{ [key: string]: FormControl<any> }>>([]),

    index: new FormControl<number | undefined>(undefined, {
      nonNullable: true,
      validators: [Validators.pattern('^[0-9]*$'), Validators.min(0), Validators.max(999999999999)],
    }),

    sottoSettori: new FormControl<SottoSettore[]>([]),

    minLivelloRispostaAutomatica: new FormControl<number | undefined>(undefined),
  });


  public formControlChipSottoSettori = new FormControl<string>('');

  public sottoSettoriFiltrati!: Observable<Settore[]>;

  /**
  * Volori del filtro valorizzati dalla dialog del filtro
  */
  private _valoriFiltro: Filters[] = [];

  @Input()
  get valFiltri(): Filters[] {
    return this._valoriFiltro;
  }

  /**
* Filtri impostati da iterfaccia
*/
  private _filtri: { [key: string]: any }[] = []

  constructor(
    private impattoService: ImpattoService,
    public dialogRef: MatDialogRef<DialogCreaCfgTematicaComponent>,
    private tematicheService: TematicheService,
    private utilityService: UtilityService,
    private ambitiService: AmbitiService,
    private impegniService: ImpegniService,
    private kpiService: KPIService,
    private azioniBPService: AzioneEBuonaPraticaService,
    private certificazioniService: CertificazioniService,
    private pianoService: PianoService,
    public dialog: MatDialog,
    private settoriService: SettoriService,
    @Inject(MAT_DIALOG_DATA) public data: {
      tematica: Tematica
    }
  ) {
    this.getStdr();
  }

  ngAfterViewInit(): void {
    this._datiIniziali();
  }

  /**
   * Compara due record per il campo "codice"
   * 
   * @param elem1
   * @param elem2 
   * @returns 
   */
  compareByChiave(elem1: SDGS, elem2: SDGS): boolean {
    return elem1.chiave === elem2.chiave;
  }

  /**
   * Compara due record per il campo "id"
   * 
   * @param elem1 
   * @param elem2 
   * @returns 
   */
  compareById(elem1: any, elem2: any): boolean {
    return elem1.id === elem2.id; // Sostituisci "id" con la proprietà univoca delle categorie
  }


  /**
   * Mappo l'oggetto per Front-end per quanto riguarda le sottoopzioni. Usato per visualizzare i dati corretti in modofica
   */
  mapOggettoPerFe(opz: any) {

    // RITORNO UN FORM GROUP

    let formRisposta: any;

    formRisposta = new FormGroup({
      valore: new FormControl<KPI | undefined>(opz, {
        nonNullable: true,
        validators: [Validators.required, UtilityService.blankValidator],
      }),
      punteggio: new FormControl<number | undefined>(opz.punteggio, [Validators.required]),
      sottoOpzioni: new FormArray([]),
      tipo: new FormControl<string>(opz.tipo || ''),
    });

    let newForm = this.formTematiche.getRawValue();

    if (newForm?.opzioni) {

      newForm.opzioni = newForm.opzioni.map((opzione) => {
        const sottoOpzioniValori = opzione['sottoOpzioni'].map((sottoOpzione: any) => ({
          ...sottoOpzione?.valore,
          tipo: sottoOpzione?.tipo,
          punteggio: sottoOpzione?.punteggio
        }));
        return {
          ...opzione,
          sottoOpzioni: sottoOpzioniValori,
        };
      });

    }
    return newForm;
  }
  /**
   * Mappo l'oggetto per back-end per quanto riguarda le sottoopzioni. Usato per mandare struttura corretta al BE 
   */
  mapOggettoPerBe() {

    let newForm = this.formTematiche.getRawValue();

    if (newForm?.opzioni) {

      newForm.opzioni = newForm.opzioni.map((opzione) => {

        const sottoOpzioniValori = opzione['sottoOpzioni'].map((sottoOpzione: any) => ({
          ...(sottoOpzione?.tipo === 'checkbox' ? { valore: sottoOpzione?.valore } : { ...sottoOpzione?.valore }),
          tipo: sottoOpzione?.tipo,
          punteggio: sottoOpzione?.punteggio
        }));
        return {
          ...opzione,
          sottoOpzioni: sottoOpzioniValori,
        };
      });


    }
    return newForm;
  }

  /**
 * Funzione di salvataggio
 * @returns 
 */

  public salva() {




    this.formTematiche.get('impatti')?.setValue(this.arrayImpattiSel);

    this.formTematiche.get('impegni')?.setValue(this.arrayImpegniSel);
    this.formTematiche.get('kpis')?.setValue(this.arrayKpiSel);
    this.formTematiche.get('azioni')?.setValue(this.arrayAzioneBPsel);
    this.formTematiche.get('certificazioni')?.setValue(this.arrayCertificazioniSel);
    this.formTematiche.get('obiettivi')?.setValue(this.arrayObiettiviSel);





    let nuovoOggetto = this.mapOggettoPerBe();

    if (nuovoOggetto?.aree?.includes('autovalutazioni') && !nuovoOggetto?.opzioni?.length) {
      this.utilityService.openDialog({
        titolo: 'Attenzione',
        descrizione: 'Inserisci una o più risposte con area Autovalutazioni',
        bottoni: [{ nome_btn: 'Chiudi' }]
      })

      return;
    }


    if (this.formTematiche.valid) {
      this.spinnerOver.show();
      const id = nuovoOggetto?.id;

      if (id) {

        return this.tematicheService.putTematica(id, nuovoOggetto).subscribe(
          {
            next: (result: any) => {
              this.spinnerOver.hide();
              this.utilityService.opneSnackBar('Aggiornamento effettuato ', '', {
                duration: 2000,
                panelClass: ['success-snackbar']
              });

              // this.dialogRef.close();
            },
            error: (err: any) => {
              this.spinnerOver.hide();
              console.error('Errore durante il salvataggio delle tematiche:', err);
              this.utilityService.openDialog({
                titolo: 'Attenzione',
                descrizione: 'Errore nel salvataggio delle tematiche',
                bottoni: [{ nome_btn: 'Chiudi' }]
              })
            }

          }
        );
      } else {

        return this.tematicheService.postTematiche(nuovoOggetto).subscribe(
          {
            next: (result: any) => {
              this.spinnerOver.hide();
              this.utilityService.opneSnackBar('Salvataggio effettuato ', '', {
                duration: 2000,
                panelClass: ['success-snackbar']
              });

              if (result?.body) {
                this._initTematica(result.body);
              }
              // this.dialogRef.close();
            },
            error: (err: any) => {
              this.spinnerOver.hide();
              console.error('Errore durante il salvataggio delle tematiche:', err);
              this.utilityService.openDialog({
                titolo: 'Attenzione',
                descrizione: 'Errore nel salvataggio delle tematiche',
                bottoni: [{ nome_btn: 'Chiudi' }]
              })
            }

          }
        );
      }
    } else {
      Object.values(this.formTematiche.controls).forEach(
        (control) => {
          control.markAsTouched();
        }
      );
      /* Controllo validazione dei campi form dentro al campo opzioni. E' un formArray */
      Object.values(this.formTematiche.controls.opzioni.controls).forEach(
        (control) => {
          if (control.invalid) {
            if (control.controls) {
              // Se il control ha controlli figli (come un FormGroup o FormArray), itera su di essi.
              Object.values(control.controls).forEach((subControl) => {
                subControl.markAsTouched();
              });
            } else {
              // Se il control è un FormControl, puoi semplicemente fare markAsTouched.
              control.markAsTouched();
            }
          }
        }
      );

    }
    return null;
  }


  addRiga(formArrayRisposte: FormArray, sottoOpzione?: boolean) {
    this.espandi = true;
    if (sottoOpzione) {
      this.isSottoRisp = true;
      this.isKpi = false;
      this.isAzione = false;
    } else {
      this.isSottoRisp = true;
      this.isKpi = true;
      this.isAzione = true;
    }

    const formRisposta = new FormGroup({
      chiave: new FormControl<string>(''),
      id: new FormControl<string>(''),
      note: new FormControl<string>(''),
      valore: new FormControl<string>('', [Validators.required, UtilityService.blankValidator]),
      nc: new FormControl<boolean | undefined>(undefined),
      punteggio: new FormControl<number | undefined>(undefined, [Validators.required]),
      sottoOpzioni: new FormArray([]),
      tipo: new FormControl<string>(sottoOpzione ? 'checkbox' : ''),
    });

    formArrayRisposte.push(formRisposta);
  }

  addRigaKpi(formArrayRisposte: FormArray) {

    this.isSottoRisp = false;
    this.isKpi = true;
    this.isAzione = false;

    const formRisposta = new FormGroup({
      valore: new FormControl<KPI | undefined>(undefined, {
        nonNullable: true,
        validators: [Validators.required, UtilityService.blankValidator],
      }),
      nc: new FormControl<boolean | undefined>(undefined),
      punteggio: new FormControl<number | undefined>(undefined, [Validators.required]),
      sottoOpzioni: new FormArray([]),
      tipo: new FormControl<string>('kpi'),
    });

    formArrayRisposte.push(formRisposta);
  }

  addRigaAzione(formArrayRisposte: FormArray) {

    this.isSottoRisp = false;
    this.isKpi = false;
    this.isAzione = true;

    const formRisposta = new FormGroup({
      valore: new FormControl<AzioneBP | undefined>(undefined, {
        nonNullable: true,
        validators: [Validators.required, UtilityService.blankValidator],
      }),
      nc: new FormControl<boolean | undefined>(undefined),
      punteggio: new FormControl<number | undefined>(undefined, [Validators.required]),
      sottoOpzioni: new FormArray([]),
      tipo: new FormControl<string>('azione'),
    });

    formArrayRisposte.push(formRisposta);
  }

  elimina(formArrayRisposte: FormArray, index: number) {
    formArrayRisposte.removeAt(index);
    if (!formArrayRisposte.length) {
      this.isSottoRisp = true;
      this.isKpi = true;
      this.isAzione = true;
    }
  }

  /*GESTIONE AUTOCOMPLETE DEI KPI NELLA TAB RISPOSTE  */
  getKPIAutoComplete = (id: any, txtRicerca: string) => {

    let records: KPI[] = [];

    if (id) {
      records = this.arrayKpiAutoCompelte.filter(option => option.id?.toLowerCase() === id);
    } else if (txtRicerca) {
      records = this._filterKpi(txtRicerca as string);
    } else {
      records = this.arrayKpiAutoCompelte.slice();
    }

    return of(records);
  }

  displayFn(kpi: KPI): string {
    return kpi && kpi.descrizione ? kpi.descrizione : '';
  }


  private _filterKpi(categoria: string): KPI[] {
    const filterValue = categoria.toLowerCase();
    return this.arrayKpiAutoCompelte.filter(option => option.descrizione?.toLowerCase().includes(filterValue));
  }

  /*GESTIONE AUTOCOMPLETE DEI KPI NELLA TAB RISPOSTE  */
  getAzioneAutoComplete = (id: any, txtRicerca: string) => {

    let records: AzioneBP[] = [];

    if (id) {
      records = this.arrayAzioneBPAutoCompelte.filter(option => option.id?.toLowerCase() === id);
    } else if (txtRicerca) {
      records = this._filterAzione(txtRicerca as string);
    } else {
      records = this.arrayAzioneBPAutoCompelte.slice();
    }

    return of(records);
  }

  displayFnAzione(azione: AzioneBP): string {
    return azione && azione.titolo ? azione.titolo : '';
  }


  private _filterAzione(categoria: string): AzioneBP[] {
    const filterValue = categoria.toLowerCase();
    return this.arrayAzioneBPAutoCompelte.filter(option => option.titolo?.toLowerCase().includes(filterValue));
  }

  onTabChange(event: MatTabChangeEvent) {

    // Quando cambio la tab ricarcio la lista degli Disponibli
    //  questo per pulire i filtri poichè rimanevano sporchi con la tab precedente.
    this._valoriFiltro = [];
    switch (event.tab.textLabel) {
      case 'Impatti':
        this._getImpatti();
        break;
      case 'Impegni':
        this._getImpegni();
        break;
      case 'Buone Pratiche':
        this._getAzioniBP();
        break;
      case 'KPI':
        this._getkPI();
        break;
      case 'Certificazioni':
        this._getCertificazioni();
        break;
      case 'Obiettivi':
        this._getObiettivi();
        break;
      default:
        break;
    }
  }

  drop(event: CdkDragDrop<any[]>) {

    const rules = this.formTematiche.get('opzioni') as FormArray;

    const currentGroup = rules.at(event.previousIndex);
    rules.removeAt(event.previousIndex);
    rules.insert(event.currentIndex, currentGroup);

  }

  dropSotto(event: CdkDragDrop<any[]>, formArraySotto: FormArray) {

    const rules = formArraySotto as FormArray;

    const currentGroup = rules.at(event.previousIndex);
    rules.removeAt(event.previousIndex);
    rules.insert(event.currentIndex, currentGroup);

  }

  private _getAmbiti() {
    return this.ambitiService.getAmbiti(0, 1000, '', [], [
      {
        chiave: 'index',
        sort: 'asc',
      },
      {
        chiave: 'descrizione',
        sort: 'asc'
      }]);
  }

  private _initTematica(tematica: Tematica) {

    Object.keys(tematica).forEach((value, index, array) => {
      if (value && (tematica as any)[value]) {

        if (value === 'opzioni') {

          /* mapOggettoPerFe */
          const opzioni = (tematica as any)[value];

          (this.formTematiche.get('opzioni') as FormArray).setValue([]);

          opzioni.forEach((opz: any) => {

            const formRisposta = new FormGroup({
              chiave: new FormControl<string>(opz.chiave || ''),
              id: new FormControl<string>(opz.id || ''),
              note: new FormControl<string>(opz.note || ''),
              valore: new FormControl<string>(opz.valore || '', [Validators.required, UtilityService.blankValidator]),
              punteggio: new FormControl<number | undefined>(opz.punteggio, [Validators.required]),
              nc: new FormControl<boolean>((opz.punteggio === undefined || opz.punteggio === null ? true : false)),
              sottoOpzioni: new FormArray([]),
              tipo: new FormControl<string>(opz.tipo || ''),
            });


            if (opz.punteggio === undefined || opz.punteggio === null) {
              formRisposta.get('punteggio')?.disable();
            } else {
              formRisposta.get('punteggio')?.enable();
            }

            if (opz.sottoOpzioni?.length) {
              (formRisposta.get('sottoOpzioni') as FormArray).setValue([]);
              for (const opzione of opz.sottoOpzioni) {

                let formSottopz: any;
                if (opzione.tipo === 'kpi') {

                  formSottopz = new FormGroup({
                    valore: new FormControl<KPI | undefined>(opzione, {
                      nonNullable: true,
                      validators: [Validators.required, UtilityService.blankValidator],
                    }),
                    punteggio: new FormControl<number | undefined>(opzione.punteggio, [Validators.required]),
                    nc: new FormControl<boolean>((opz.punteggio === undefined || opz.punteggio === null ? true : false)),
                    sottoOpzioni: new FormArray([]),
                    tipo: new FormControl<string>(opzione.tipo || ''),
                  });

                } else if (opzione.tipo === 'azione') {

                  formSottopz = new FormGroup({
                    valore: new FormControl<AzioneBP | undefined>(opzione, {
                      nonNullable: true,
                      validators: [Validators.required, UtilityService.blankValidator],
                    }),
                    nc: new FormControl<boolean>((opz.punteggio === undefined || opz.punteggio === null ? true : false)),
                    punteggio: new FormControl<number | undefined>(opzione.punteggio, [Validators.required]),
                    sottoOpzioni: new FormArray([]),
                    tipo: new FormControl<string>(opzione.tipo || ''),
                  });

                } else if (opzione.tipo === 'checkbox' || opzione.tipo === '') {

                  formSottopz = new FormGroup({
                    chiave: new FormControl<string>(opzione.chiave || ''),
                    id: new FormControl<string>(opzione.id || ''),
                    note: new FormControl<string>(opzione.note || ''),
                    valore: new FormControl<string>(opzione.valore || '', [Validators.required, UtilityService.blankValidator]),
                    punteggio: new FormControl<number | undefined>(opzione.punteggio, [Validators.required]),
                    nc: new FormControl<boolean>((opzione.punteggio === undefined || opzione.punteggio === null ? true : false)),
                    sottoOpzioni: new FormArray([]),
                    tipo: new FormControl<string>(opzione.tipo || ''),
                  });
                }

                if (opzione.punteggio === undefined || opzione.punteggio === null) {
                  formSottopz.get('punteggio')?.disable();
                } else {
                  formSottopz.get('punteggio')?.enable();
                }

                (
                  formRisposta.get('sottoOpzioni') as FormArray
                ).push(
                  formSottopz
                );
                /*  this.ctrBottoni(formRisposta.get('sottoOpzioni')?.value); */ //se expand = false inizialmentes
              }
            }

            (this.formTematiche.get('opzioni') as FormArray).push(formRisposta);


          })

        } else if (value === 'datiAmbiti') {

          const ambiti = (tematica as any)[value];

          const arrayIdAmb: string[] = ambiti.map((amb: Ambito) => amb.id);

          this.formTematiche.get('idsAmbiti')?.setValue(arrayIdAmb);

        } else if (value === 'sdgs') {
          this.formTematiche.get('sdgs')?.setValue((tematica?.sdgs || [])
            .map((value) => {
              return this.arraySdgs.find((valueSdgs) => valueSdgs.chiave === value.chiave);
            })
            .filter((value) => !!value) as SDGS[])

        } else if (value === 'sottoSettori') {
          this.formTematiche.get('sottoSettori')?.setValue((tematica?.sottoSettori || []));
        } else {

          this.formTematiche.get(value)?.setValue((tematica as any)[value]);
        }


      }
    })

    // riempio arrayDiImpattiSel per vedere gli impatti selezionati in modifica
    this.arrayImpattiSel = (this.formTematiche.get('impatti')?.value) as Impatto[];
    //rimuovo gli impatti selezionati da quelli disponibili 
    this.arrayImpattiDisponibili = this.arrayImpattiDisponibili.filter(impattoSel => !this.arrayImpattiSel.some(item2 => impattoSel.id === item2.id));

    this.arrayKpiSel = (this.formTematiche.get('kpis')?.value) as KPI[];
    this.arrayKpi = this.arrayKpi.filter(kpiSel => !this.arrayKpiSel.some(item2 => kpiSel.id === item2.id));

    this.arrayImpegniSel = (this.formTematiche.get('impegni')?.value) as Impegno[];
    this.arrayImpegni = this.arrayImpegni.filter(impegniSel => !this.arrayImpegniSel.some(item2 => impegniSel.id === item2.id));

    this.arrayAzioneBPsel = (this.formTematiche.get('azioni')?.value) as AzioneBP[];
    this.arrayAzioneBP = this.arrayAzioneBP.filter(azioneSel => !this.arrayAzioneBPsel.some(item2 => azioneSel.id === item2.id));

    this.arrayCertificazioniSel = (this.formTematiche.get('certificazioni')?.value) as Certificazione[];
    this.arrayCertificazioni = this.arrayCertificazioni.filter(certificazioneSel => !this.arrayCertificazioniSel.some(item2 => certificazioneSel.id === item2.id));

    this.arrayObiettiviSel = (this.formTematiche.get('obiettivi')?.value) as Obiettivo[];
    this.arrayObiettivi = this.arrayObiettivi.filter(obiettivoSel => !this.arrayObiettiviSel.some(item2 => obiettivoSel.id === item2.id));


  }

  dropImpatti(event: CdkDragDrop<Impatto[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
  }

  cambioNc(event: any, idx: number) {


    const formOpz: FormGroup = this.formTematiche.controls.opzioni.controls[idx];



    if (event.checked) {
      formOpz.get('punteggio')?.setValue(null);
      formOpz.get('punteggio')?.disable();
    } else {
      formOpz.get('punteggio')?.setValue(0);
      formOpz.get('punteggio')?.enable();
    }

  }

  dropImpegni(event: CdkDragDrop<Impegno[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
  }

  dropKpi(event: CdkDragDrop<KPI[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
  }

  dropAzioneBP(event: CdkDragDrop<AzioneBP[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
  }

  dropCertificazioni(event: CdkDragDrop<Certificazione[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
  }

  dropObiettivi(event: CdkDragDrop<Obiettivo[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
  }

  public async _datiIniziali() {

    this.sottoSettoriFiltrati = this.formControlChipSottoSettori.valueChanges.pipe(
      startWith(''),
      map((value) => value || ''),
      map((value: string) =>
        value ?
          this._filtraSottoSettori(value) :
          this.arraySettori.slice())
    );

    this.formTematiche.get('aree')?.valueChanges.subscribe((risposta) => {

      if (risposta.indexOf('survey') >= 0 && risposta.indexOf('bilanci') < 0) {
        const valArea = risposta.concat('bilanci');
        this.formTematiche.get('aree')?.setValue(valArea, { emitEvent: false });

      }
    });

    this.formTematiche.get('idsAmbiti')?.valueChanges.subscribe((risposta) => {
      // Aggiorna la lista dei sottosettori disponibili
      this.getSettoriDaAmbiti();
    });

    this.spinnerOver.show();

    forkJoin({
      ambiti: this._getAmbiti(),
      impDisp: this.impattoService.getImpatti(0, 1000),
      aree: this.tematicheService.getAree(),
      impegni: this.impegniService.getImpegni(0, 1000),
      kpi: this.kpiService.getKPI(0, 1000),
      azioni: this.azioniBPService.getAzioneBP(0, 1000),
      sdgs: this.tematicheService.getSDGS()
    }).subscribe({
      next: (risp) => {

        this.arrayAmbiti = (risp.ambiti?.content) || [];

        this.arrayAmbiti = this.arrayAmbiti.sort((a, b) => {
          let indexA = a.index == null ? Infinity : a.index;
          let indexB = b.index == null ? Infinity : b.index;
          return indexA - indexB;
        }); //ordino l'array per avere le tab ordinate

        this.arrayImpattiDisponibili = risp.impDisp?.content || [];

        this.arrayImpegni = risp?.impegni?.content || [];

        this.arrayKpi = risp?.kpi?.content || [];

        this.arrayKpiAutoCompelte = this.arrayKpi;

        this.arrayAzioneBP = risp?.azioni?.content || [];

        this.arrayAzioneBPAutoCompelte = this.arrayAzioneBP;

        this.arraySdgs = risp.sdgs || [];

        this.loadingImpDisp = false;
        this.arrayAree = risp?.aree || [];

        if (this.data?.tematica) {
          this._initTematica(this.data?.tematica);
        } else {
          if (this.arrayAree?.length === 1) {
            this.formTematiche.get('aree')?.setValue([this.arrayAree[0].chiave]);
          }
        }
        this.spinnerOver.hide();
      },
      error: (err) => {
        console.error(err);

        this.spinnerOver.hide();

        this.utilityService.openDialog({
          titolo: 'Attenzione',
          descrizione: 'Errore nel recupero dei valori',
          bottoni: [{ nome_btn: 'Ok' }]
        })
      }
    })

  }

  private _filtraSottoSettori(value: string) {
    return this.arraySettori
      .map(((settore): Settore => {
        return Object.assign(
          {},
          settore,
          {
            sottoSettori: settore.sottoSettori.filter(sottoSett => {
              return (sottoSett.titolo || '').toLowerCase().indexOf((value?.toString() || '')) >= 0;
            })
          }
        );
      }))
      .filter(settore => !!settore?.sottoSettori?.length
      )
  }

  private getStdr() {
    this.tematicheService.getStdr().subscribe({
      next: (result: any) => {


        this.arrayObbligatorio = result || [];
      },
      error: (err: any) => {
        console.error('Errore durante il caricamento stdr:', err);

        this.utilityService.openDialog({
          titolo: 'Attenzione',
          descrizione: 'Errore nel recupero stdr',
          bottoni: [{ nome_btn: 'Ok' }]
        });
      }
    });
  }

  private getSettoriDaAmbiti() {

    this.spinnerOver.show();

    this.settoriService.getSettoriDaAmbiti(this.formTematiche.get("idsAmbiti")?.value || []).subscribe({
      next: (resp) => {
        this.spinnerOver.hide();

        this.arraySettori = (resp || []).filter((sett: Settore) => sett.sottoSettori?.length > 0);

        this.arraySettori.forEach((sett) => {
          sett.sottoSettori?.forEach((sottoSet) => {
            if (sottoSet.id) {
              this.settoriPerIdSottosettore[sottoSet.id] = sett;
            }
          });
        });

        const selezionati = this.formTematiche.get('sottoSettori')?.value;

        this.formTematiche.get('sottoSettori')?.setValue(
          selezionati?.filter(sottoSettSel => {
            return !!this.arraySettori.map(sett => sett.sottoSettori).flat().find(sottoSett => sottoSett.id === sottoSettSel.id)
          }) || []
        );

        this.formTematiche.get("sottoSettori")?.updateValueAndValidity();

        this.formControlChipSottoSettori.setValue(this.inputSottoSettori.nativeElement.value || '');
      },
      error: (err: any) => {
        this.spinnerOver.hide();
        console.error('Errore durante il caricamento sottoSettori:', err);

        this.utilityService.openDialog({
          titolo: 'Attenzione',
          descrizione: 'Errore nel recupero sotto-settori',
          bottoni: [{ nome_btn: 'Ok' }]
        });
      }
    })
  }

  rimuoviSottoSettore(sottoSettore: SottoSettore) {


    if (sottoSettore) {
      const selezionati = (this.formTematiche.get('sottoSettori')?.value || []).filter((sottoSet: SottoSettore) => sottoSet.id !== sottoSettore.id);
      this.formTematiche.get('sottoSettori')?.setValue(selezionati);
    }
  }

  selezionaSottoSettore(event: MatAutocompleteSelectedEvent) {


    if (this.formTematiche.get('sottoSettori') && event?.option?.value) {
      const selezionati = this.formTematiche.get('sottoSettori')!.value || [];

      const trovato = selezionati.find((sottoSett: SottoSettore) => sottoSett.id === event.option.value.id);

      if (!trovato) {
        selezionati.push(event.option.value);
        this.formTematiche.get('sottoSettori')!.setValue(selezionati);
      }
    }

    this.inputSottoSettori.nativeElement.value = '';

    this.formControlChipSottoSettori.setValue(null);
  }


  onSelectionChangeAree(event: MatSelectChange) {
    if (event.value.includes('survey')) {
      this.areaSelezionata = true;
    } else {
      this.areaSelezionata = false;
    }
  }

  public apriFiltro(filtroFrom: string) {
    // Uso un oggetto per mappare i casi e le funzioni corrispondenti
    const filterActions = {
      impatti: () => this._configuraFiltri('titolo', 'tag', (ricerca: any) => this.impattoService.getImpattiTags(0, 1000, ricerca)),
      impegni: () => this._configuraFiltri('titolo', 'tag', (ricerca: any) => this.impegniService.getImpegniTags(0, 1000, ricerca)),
      azioniBP: () => this._configuraFiltri('titolo', 'tag', (ricerca: any) => this.azioniBPService.getAzioneBPTags(0, 1000, ricerca)),
      kpi: () => this._configuraFiltri('descrizione', 'tag', (ricerca: any) => this.kpiService.getKPITags(0, 1000, ricerca)),
      certificazioni: () => this._configuraFiltriCertificazioni('nomeBreve', 'descrizione'),
      obiettivi: () => this._configuraFiltriObiettivi('titolo', 'descrizione'),
    };

    // Tipo per le chiavi dell'oggetto filterActions
    type FilterActionsKey = keyof typeof filterActions;

    // Verifica se la chiave fornita è valida
    if (filtroFrom in filterActions) {
      this._filtri = filterActions[filtroFrom as FilterActionsKey]();
    }

    const dialogFiltro = this.dialog.open(DialogFiltroGenericoComponent, {
      data: {
        filtri: this._filtri,
        valoriFiltro: this._valoriFiltro,
      },
      panelClass: 'dialog-container',
      disableClose: false,
      width: '30%',
      maxHeight: '90%',
      autoFocus: false
    });

    dialogFiltro.afterClosed().subscribe(result => {
      if (result) {

        this._valoriFiltro = result.filtri;

        switch (filtroFrom) {
          case 'impatti':
            this._getImpatti();
            break;
          case 'impegni':
            this._getImpegni();
            break;
          case 'azioniBP':
            this._getAzioniBP();
            break;
          case 'kpi':
            this._getkPI();
            break;
          case 'certificazioni':
            this._getCertificazioni();
            break;
          case 'obiettivi':
            this._getObiettivi();
            break;
          default:
            break;
        }
      }
    });
  }

  private _getImpatti() {
    this.impattoService.getImpatti(0, 1000, undefined, this._valoriFiltro).subscribe({
      next: (esito => {

        this.arrayImpattiDisponibili = esito.content || [];

        this.arrayImpattiDisponibili = this.arrayImpattiDisponibili.filter(impattoSel => !this.arrayImpattiSel.some(item2 => impattoSel.id === item2.id));
      }),
      error: (err => {
        console.error(err);
      })
    })
  }
  private _getImpegni() {
    this.impegniService.getImpegni(0, 1000, undefined, this._valoriFiltro).subscribe({
      next: (esito => {
        this.arrayImpegni = esito.content || [];

        //rimuovo gli impegni selezionati da quelli disponibili 
        this.arrayImpegni = this.arrayImpegni.filter(impegniSel => !this.arrayImpegniSel.some(item2 => impegniSel.id === item2.id));
      }),
      error: (err => {
        console.error(err);
      })
    })
  }
  private _getAzioniBP() {
    this.azioniBPService.getAzioneBP(0, 1000, undefined, this._valoriFiltro).subscribe({
      next: (esito => {

        this.arrayAzioneBP = esito.content || [];

        //rimuovo le azioni e buone pratiche selezionati da quelli disponibili 
        this.arrayAzioneBP = this.arrayAzioneBP.filter(azioneSel => !this.arrayAzioneBPsel.some(item2 => azioneSel.id === item2.id));
      }),
      error: (err => {
        console.error(err);
      })
    })
  }
  private _getkPI() {
    this.kpiService.getKPI(0, 1000, undefined, this._valoriFiltro).subscribe({
      next: (esito => {

        this.arrayKpi = esito.content || [];

        //rimuovo kpi selezionati da quelli disponibili 
        this.arrayKpi = this.arrayKpi.filter(kpiSel => !this.arrayKpiSel.some(item2 => kpiSel.id === item2.id));
      }),
      error: (err => {
        console.error(err);
      })
    })
  }

  private _getCertificazioni() {
    this.certificazioniService.getCert(0, 1000, undefined, this._valoriFiltro).subscribe({
      next: (esito => {
        this.arrayCertificazioni = esito.content || [];
        //rimuovo certificazioni selezionate da quelli disponibili
        this.arrayCertificazioni = this.arrayCertificazioni.filter(certificazioneSel => !this.arrayCertificazioniSel.some(item2 => certificazioneSel.id === item2.id));
      }),
      error: (err => {
        console.error(err);
      })
    });
  }

  /**
   * Metodo che mi ritorna la lista degli obiettivi 
   */
  private _getObiettivi() {
    this.pianoService.getObiettivi(0, 1000, undefined, this._valoriFiltro).subscribe({
      next: (esito => {
        this.arrayObiettivi = esito.content || [];
        //rimuovo obiettivi selezionati da quelli disponibili
        this.arrayObiettivi = this.arrayObiettivi.filter(obiettivoSel => !this.arrayObiettiviSel.some(item2 => obiettivoSel.id === item2.id));
      }),
      error: (err => {
        console.error(err);
      })
    });
  }

  // Prima, definisci un metodo per configurare i filtri comuni:
  private _configuraFiltri(filtro1: string, filtro2: string, metodoService: any) {
    return [
      {
        titolo: filtro1 === 'titolo' ? 'Titolo' : 'Descrizione',
        forControlName: filtro1,
        input: 'text',
        larghezza: 3,
      },
      {
        titolo: 'TAG',
        forControlName: filtro2,
        input: 'multiple-option-chip',
        riempi: true,
        larghezza: 3,
        fnDatiOption: metodoService,
        numeroRecOptions: Infinity
      },
    ];
  }

  private _configuraFiltriCertificazioni(filtro1: string, filtro2: string) {
    return [
      {
        titolo: 'Nome',
        forControlName: filtro1,
        input: 'text',
        larghezza: 3
      },
      {
        titolo: 'Descrizione',
        forControlName: filtro2,
        input: 'text',
        riempi: true,
        larghezza: 3,
      },
    ];
  }

  private _configuraFiltriObiettivi(filtro1: string, filtro2: string) {
    return [
      {
        titolo: 'Titolo',
        forControlName: filtro1,
        input: 'text',
        larghezza: 3
      },
      {
        titolo: 'Descrizione',
        forControlName: filtro2,
        input: 'text',
        riempi: true,
        larghezza: 3,
      },
    ];
  }
  /**
   * Controllo quali bottoni mostrare per quando si entra in modifica e si apre l'expansion
   * @param elemento 
   */
  ctrBottoni(elemento: any) {

    if (!elemento.length) {
      this.isSottoRisp = true;
      this.isKpi = true;
      this.isAzione = true;
    } else {

      elemento.forEach((sottoOpz: any) => {

        if (sottoOpz?.tipo === 'checkbox') {
          this.isSottoRisp = true;
          this.isKpi = false;
          this.isAzione = false;
        } else {
          this.isSottoRisp = false;
          this.isKpi = true;
          this.isAzione = true;
        }
      });
    }
  }
  onKeyDown(event: KeyboardEvent): void {
    // Verifica se il tasto premuto è il carattere "-"
    if (event.key === '-') {
      // Impedisce l'inserimento del carattere "-"
      event.preventDefault();
    }
  }
}
