<app-spinner-overlay></app-spinner-overlay>
<mat-tab-group (selectedTabChange)="onTabChange($event)" #tabGroupAmbiti
    [ngClass]="this.chiaveElementoSelezione === 'targetQualitativi' || this.chiaveElementoSelezione === 'targetQuantitativi' ? 'nascondi-tab' : undefined ">
    <mat-tab [label]="ambito.titoloBreve" *ngFor="let ambito of objQuestionario">
        <div class="bordo">
            <ng-container *ngIf="objQuestionario">
                <ng-scrollbar>
                    <div style="padding-right: 15px;">
                        <div class="titolo-sezione-dialog" style="font-size: 15px;">
                            {{ambito.titolo}}</div>
                        <div *ngFor="let tematica of ambito.tematiche" class="tematica-group">

                            <div [id]="ambito.id + '_' + tematica.id + chiaveElementoSelezione"
                                [ngClass]="{'domanda-tematica': !ctrTematicaNonCompletata(ambito.id + '_' +  tematica.id), 'domanda-tematica-rossa': ctrTematicaNonCompletata(ambito.id + '_' +  tematica.id)}">
                                {{tematica.domanda}}</div>

                            <div>
                                <div *ngFor="let elementoChk of tematica[chiaveElemento]" style="display: flex;">

                                    <mat-checkbox [checked]="elementoChk.selected" #checkboxElemento
                                        class="radio-group checkbox-selezione-elementi"
                                        [disabled]="controlloElementoDisabled(elementoChk) || this.isPermessoSoloRead"
                                        (change)="selezionaCheck(elementoChk,$event.checked,ambito.id, tematica.id);">
                                    </mat-checkbox>
                                    <!--     ctrCheck(ambito.id, tematica.id, elementoChk, $event); updateFormStatus()-->
                                    <div style="display: flex;flex-direction: column;">

                                        <div
                                            (click)="$event.stopPropagation(); selezionaCheck(elementoChk,!elementoChk.selected,ambito.id, tematica.id)">

                                            <mat-label style="display: flex;align-items: center;">

                                                <div class="titolo-elemento"
                                                    [id]="ambito.id + '_' + tematica.id + '_' + elementoChk.id">
                                                    {{elementoChk.titolo ? elementoChk.titolo : elementoChk.nomeBreve}}
                                                </div>

                                                <mat-icon *ngIf="showInfoIcon(elementoChk)"
                                                    class="material-icons-outlined icone-tem icone-label-chack"
                                                    style="color: var(--grigio);padding: 0px; width: 40px; height: 40px; display: flex; align-items: center; justify-content: center;"
                                                    [matTooltip]="getTooltip(elementoChk)">
                                                    info
                                                </mat-icon>

                                                <button mat-icon-button aria-label="Modifica impatto"
                                                    style="color: var(--grigio);padding: 0px; width: 40px ;height: 40px ;"
                                                    (click)="$event.stopPropagation(); editElementoListaChk(ambito.id, tematica.id, elementoChk )"
                                                    *ngIf="isElementoEditable(elementoChk)">
                                                    <mat-icon class="icone-label-chack">edit</mat-icon>
                                                </button>

                                                <ng-container *ngIf="chiaveElementoSelezione !== 'obiettivo'">
                                                    <div [matTooltip]="toolTipFigura(elementoChk?.figura)"
                                                        style="display: flex; height: 40px;">
                                                        <div *ngIf="!isImpatto">
                                                            <button
                                                                *ngIf="elementoChk.selected && !isMaterialitaSelezionata"
                                                                mat-icon-button aria-label="Associa Figura Aziendale"
                                                                style="color: var(--grigio);padding: 0px; width: 40px;height: 43px;"
                                                                (click)="$event.stopPropagation();associaFiguraAziendale(ambito.id, tematica.id, elementoChk.id, elementoChk?.figura)"
                                                                [disabled]="stato === 'PUBBLICATO' || isMaterialitaSelezionata || this.isPermessoSoloRead">
                                                                <mat-icon
                                                                    class="material-icons-outlined icone-label-chack"
                                                                    [ngClass]="{'blue-icon': elementoChk?.figura?.id, 'default-icon': !elementoChk?.figura?.id}">
                                                                    person</mat-icon>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                                <!-- Elemento consigliato se STDR e soglie non superate  -->
                                                <div class="consigliato"
                                                    *ngIf="elementoChk.consigliato && !isElementoObbligatorioPerStdr(elementoChk)">
                                                    *Consigliato</div>

                                            </mat-label>
                                        </div>

                                        <div style="display: flex;padding-bottom: 10px;">
                                            <p style="color:  var(--grigio);font-size: 13px;margin: 0px;">
                                                {{elementoChk.descrizione}}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <button mat-stroked-button *ngIf="!isMaterialitaSelezionata"
                                        style="height: 32px;width: 240px;font-weight: bold;background-color: #F5F5F5;border-color: #DBE5E6;"
                                        (click)="addElementoListaChk(ambito.id, tematica.id )"
                                        [disabled]="stato === 'PUBBLICATO' || isMaterialitaSelezionata || this.isPermessoSoloRead">
                                        + Aggiungi Nuovo
                                    </button>
                                </div>

                            </div>
                        </div>
                    </div>
                </ng-scrollbar>
            </ng-container>
        </div>
    </mat-tab>
</mat-tab-group>